import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { isLoggedIn } from '../../utils';


function ShippingCostModal({ show, handleClose, shippingCosts, handleShippingCostChange, address }) {
  return (
    <>
      <Modal show={show} onHide={handleClose} className="">
        <div className="padding-left-right">
          <Modal.Header closeButton>
            <Modal.Title>Change Shipping Method</Modal.Title>
          </Modal.Header>
          <Modal.Body className="padding-left-right">
            {shippingCosts.map((item, index) => {
              return (
                <div className='col-12 mb-2' key={index} onClick={() => handleShippingCostChange(index)}>
                  <div className="logistics--item">
                    <div className="dynamic-shipping">
                      <div className="dynamic-shipping-line dynamic-shipping-titleLayout">
                        <span>
                          <span style={{ fontSize: '15px', color: '#222222' }}>
                            <strong>Shipping: {localStorage.getItem('cur')}. {item.convertedFee}</strong>
                          </span>
                        </span>&nbsp;
                      </div>
                      <div className="dynamic-shipping-line dynamic-shipping-contentLayout">
                        <span>
                          <span style={{ color: '#666666' }}>Estimated delivery in </span>
                          <span style={{ color: '#222222' }}>{item.delivery_day}</span>
                        </span>&nbsp;
                      </div>
                      <div className="dynamic-shipping-line dynamic-shipping-contentLayout">
                        <span>
                          <span style={{ color: '#222222' }}>From </span>
                          <span style={{ color: '#222222' }}><strong>Bangladesh</strong></span>
                          <span style={{ color: '#222222' }}> to </span>
                          <span style={{ color: '#222222' }}><strong>{address.country}</strong></span>
                          <span style={{ color: '#222222' }}> via {item.name}</span>
                        </span>&nbsp;
                      </div>
                      {/* <div className="dynamic-shipping-line dynamic-shipping-additionLayout">
                          <span className="dynamic-shipping-mark" style={{ color: 'rgb(34, 34, 34)' }}>
                            Tracking Available
                          </span>&nbsp;
                        </div> */}
                    </div>
                    {item.selected
                      ?
                      <div className="logistics--selectedIcon">
                        <span className="comet-icon comet-icon-selected" style={{ fontSize: '12px', color: 'rgb(255, 255, 255)' }}>
                          <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                            <path d="M866.346667 266.432a32 32 0 0 1 49.344 40.618667l-2.090667 2.517333-467.477333 512a32 32 0 0 1-45.610667 1.685333l-2.325333-2.432L131.072 509.162667a32 32 0 0 1 46.336-44.010667l2.24 2.368L423.253333 751.701333l443.093334-485.269333z"></path>
                          </svg>
                        </span>
                      </div>
                      :
                      <div className="logistics--unselectedIcon"></div>
                    }
                  </div>
                </div>
              )
            })}

          </Modal.Body>
          {/* <Modal.Footer>
            <Button className="btn btn-lg" variant="danger" onClick={handleClose}>
              Cancel
            </Button>
          </Modal.Footer> */}
        </div>
      </Modal>
    </>
  );
}

export default ShippingCostModal;
