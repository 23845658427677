import React, { useState } from 'react'; // Import React and useState
import { Modal } from 'react-bootstrap'; // Import Modal from react-bootstrap
import QueryForm from '../queryFrom/QueryForm';

const QueryModal = ({ show, setShow, company, productData, preview }) => {
    // Remove the extra `show` variable and setShow since they are passed as props
    const removeQueryParameters = () => {
        const urlWithoutQueryParams = window.location.pathname;
        window.history.pushState({}, '', urlWithoutQueryParams);
    };

    const toggleShow = () => {
        setShow(false)
        removeQueryParameters();
    }
    return (
        <Modal
            dialogClassName="border-radius-10"
            show={show}
            onHide={toggleShow}
            size='md'
            centered // Center the modal vertically
        > {/* Use setShow to hide the modal */}
            <Modal.Header closeButton>
                <Modal.Title>Send Query</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: '20px' }}>
                <div className='container'>
                    <div className="inner">
                        <div className="row">
                            {/* Use productData and preview as props */}
                            <QueryForm company={company} productData={productData} preview={preview} />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn-default btn-danger btn-small " onClick={toggleShow}>
                    Close
                </button>
            </Modal.Footer>
            {/* You can include a footer with buttons here if needed */}
        </Modal>

    );
};


export default QueryModal;

