import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { get } from '../../utils/api_helper';
import moment from 'moment';
import SaarcNews from '../homepage/SaarcNews';
import AdvanceTabThree from '../advancetab/AdvanceTabThree';
import SaarcEvents from '../homepage/SaarcEvents';

const NewsAndEvents = () => {
  return (
    <div className=" container  rn-splite-style ">
      <div className="data-element-sidebar-product">
        <div className="mt-3 row align-items-center">
          <SaarcNews />
          <SaarcEvents />
        </div>
      </div>
    </div>
  );
};
export default NewsAndEvents;
