import React, { useEffect, useState } from 'react';
import SEO from '../common/SEO';
import Layout from '../common/Layout';
import { AiOutlineInfoCircle, AiOutlinePlus } from 'react-icons/all';
import Button from 'react-bootstrap/Button';
import { Redirect } from 'react-router-dom';
import { getConvertedPrice, getUserId, ORDER_STATUS } from '../utils';
import * as api from '../utils/api_helper';
import { ButtonToolbar, OverlayTrigger, Spinner, Alert, Tooltip } from 'react-bootstrap';
import BillingAddressModel from '../components/addToCartModel/DeliveryDetails';
import ProductListing from '../components/addToCartModel/ProductListing';
import { BiEdit } from 'react-icons/bi'
import ShippingCostModal from '../components/addToCartModel/ShippingCostModal';

export default function CheckOut(props) {
  //---context
  const [cart, setCart] = useState(props.location.state.cart || {});
  const [orderInformation, setOrderInformation] = useState(props.location.state.cart);
  const [order, setOrder] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [orderStatus, setOrderStatus] = useState(false);
  const [orderFailed, setOrderFailed] = useState(false);
  const [address, setAddress] = useState({});
  const [openAddressModal, setOpenModal] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [placedOrders, setPlacedOrders] = useState([]);
  const [shippingFeeUsd, setShippingFeeUsd] = useState('');
  const [selectedShippingMethods, setSelectedShippingMethods] = useState('');
  const [availableShippingMethods, setAvailableShippingMethods] = useState([]);
  const [totalShippingCost, setTotalShippingCost] = useState('');

  const userId = getUserId();
  // let keys = ['product_id', 'product_title', 'qty', 'price', 'supplier_id', 'supplier_name', 'variation_id', 'state', 'currency', 'image', 'country', 'user_country'];

  const tooltip = <Tooltip id="tooltip">Shipping fee is calculated only for products from Bangladesh</Tooltip>;
  const onChange = (e) => { setOrderInformation({ ...orderInformation, [e.target.name]: e.target.value }) };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    if (cart?.shipping_cost_usd) {
      setTotalShippingCost(cart.shipping_cost_usd)
    }
  }, [cart.shipping_cost_usd])

  const deleteProduct = (index) => {
    Object.keys(cart).map((item) => {
      if (
        item !== 'id' &&
        item !== 'createdAt' &&
        item !== 'deletedAt' &&
        item !== 'updatedAt' &&
        item !== 'UserId' &&
        item !== 'state'
      ) {
        if (cart && cart[item]) {
          let temp = cart[item].split(';');
          temp.splice(index, 1);
          cart[item] = temp.join(';');
        }
      }
    });
    updateCart();
  };

  const changeQuantity = async (change, index) => {
    if (change === 'add') {
      if (cart && cart.qty) {
        let qty = cart.qty.split(';'); //save quantities in the form of array
        qty[index] = parseInt(qty[index]) + 1; //increment the specific quantity as of index, also convert it into integer before adding
        cart.qty = qty.join(';'); //Change quantity back to string
        const status = await updateCart(); //update the cart
      }
    } else if (change === 'subtract') {
      if (cart && cart.qty) {
        let qty = cart.qty.split(';'); //save quantities in the form of array
        if (qty[index] === '1') {
          deleteProduct(index);
        } else {
          qty[index] = parseInt(qty[index]) - 1; //decrement the specific quantity as of index, also convert it into integer before adding
          cart.qty = qty.join(';'); //Change quantity back to string
          const status = await updateCart(); //update the cart
        }
      }
    }
  };

  const updateCart = async () => {
    api
      .put('/cart/' + cart.id, cart)
      .then((response) => {
        setRefetch(!refetch);
        return true;
      })
      .catch((error) => {
        console.error('Cart can not be updated', error);
        setRefetch(!refetch);
        return false;
      });
  };

  useEffect(() => {
    setOrderInformation({ ...orderInformation, ...address });
  }, [address])

  useEffect(() => {
    const getUserAddress = async () => {
      await api.get(`addresses/user/${userId}`)
        .then((response) => {
          if (response && response.length > 0) {
            setAddress(response[0]);
          }
        })
        .catch((error) => console.log(error));
    }

    getUserAddress();
  }, [userId])

  // Submit orders
  const submitOrders = async () => {
    let error = false;

    if (!isChecked) {
      setTermsError(true);
      error = true;
    }

    // Check if billing information is missing
    if (!orderInformation.name || !orderInformation.address || !orderInformation.phone || !orderInformation.email || !orderInformation.city || !orderInformation.country || !orderInformation.zip) {
      setAddressError(true);
      error = true;
    }

    if (error) {
      return;
    }


    const totalProducts = cart.product_id.split(';');
    let tempOrderSuccess = [];
    for (let i = 0; i < totalProducts.length; i++) {
      if (cart.status.split(';')[i] == 'false' || cart.status.split(';')[i] == '') {
        // go to next iteration
        continue;
      }

      orderInformation.product_id = cart.product_id.split(';')[i];
      orderInformation.product_title = cart.product_title.split(';')[i];
      orderInformation.qty = cart.qty?.split(';')[i];
      orderInformation.price = cart.price.split(';')[i];
      orderInformation.currency = cart.currency.split(';')[i];
      orderInformation.supplier_id = cart.supplier_id.split(';')[i];
      orderInformation.user_id = userId;
      orderInformation.supplier_name = cart.supplier_name.split(';')[i];
      orderInformation.image = cart.image?.split(';')[i];
      orderInformation.user_country = orderInformation?.country;
      orderInformation.country = cart?.country?.split(';')[i];
      orderInformation.variation_id = cart?.variation_id?.split(';')[i];
      orderInformation.followedCheckoutProcess = cart?.followedCheckoutProcess ? false : true;

      // set shipping cost, method, package weight, weight per item
      orderInformation.shipping_method = cart?.shipping_methods?.split(';')[i];
      orderInformation.shipping_cost_usd = cart?.shipping_cost_usd?.split(';')[i];
      orderInformation.package_weight = cart?.package_weight?.split(';')[i];
      orderInformation.weight_per_item = cart?.weight_per_item?.split(';')[i];

      setRefresh(true);

      if (orderInformation.shipping_cost_usd == '') {
        orderInformation.shipping_cost_usd = '0';
      }

      console.log("Order information", orderInformation)

      // place order
      await api
        .post('/orders/add', orderInformation)
        .then(async (response) => {
          tempOrderSuccess.push(true);

          // Add placed orders to placed orders array
          if (response.success) {
            placedOrders.push(response.order);
          }
        })
        .catch((error) => {
          console.error('Request denied', error);
          tempOrderSuccess.push(false);
          setOrderFailed(true);
        });
    }

    // If any order is placed then show order status
    if (tempOrderSuccess.includes(true)) {
      setOrderStatus(true);
    }

    setRefresh(false);
  };

  // Set error messages
  useEffect(() => {
    if (addressError || termsError) {
      setTimeout(() => { setAddressError(false); setTermsError(false) }, 5000)
    }
  }, [addressError, termsError])


  useEffect(() => {
    if (cart) {
        cart.shipping_fee_usd = '';
        getShippingFee();
    }
  }, [])

  const getShippingFee = async () => {
    const packageWeights = cart.package_weight.split(';');
    
    if (packageWeights.length > 0) {
      const shippingFeeUsdArray = [];
      const shippingMethodsArray = [];
      const shippingMethodsForAllProducts = [];
      for (let index in packageWeights) {
        const packageWeight = packageWeights[index];

        const body = {
          security_type: 'regular',
          ins_amount: 0,
          country: 'Pakistan',
          weight: packageWeight
        }

        // get product shippings
        await api.post('orders/getShippingCost', body)
          .then(async (response) => {
            if (response.success) {
              const shippingMethods = response.data;

              // Select shipping method by default
              let defaultShippingFee = "0";
              let defaultShippingMethod = "";
              let convertedShippingFee = "";

              const promises = shippingMethods.map(async (fee) => {
                  // bdt is Bangladeshi Taka denomination
                  const convertedFee = await getConvertedPrice('bdt', fee.calculation.goods)
                  fee.convertedFee = convertedFee;
              });

              // Wait for the above loop to end its execution
              await Promise.all(promises);

              for (let fee of shippingMethods) {
                if (packageWeight <= fee.max_weight_parcel) {
                  defaultShippingFee = fee.calculation.goods.toString();
                  // bdt is bangali taka denotion
                  console.log('what', fee.convertedFee)
                  convertedShippingFee = fee.convertedFee;
                  defaultShippingMethod = fee.name;
                  fee.selected = true;
                  break;
                }
              }
              
              console.log('converted price', convertedShippingFee)
              shippingFeeUsdArray.push(convertedShippingFee);
              // shippingFeeUsdArray.push(defaultShippingFee);
              shippingMethodsArray.push(defaultShippingMethod);
              shippingMethodsForAllProducts.push(shippingMethods);
            }
          })
          .catch((error) => {
            console.log('Error getting shipping fee', error);
          })
      }
      cart.shipping_cost_usd = shippingFeeUsdArray.join(';');
      cart.shipping_methods = shippingMethodsArray.join(';');
      setShippingFeeUsd(shippingFeeUsdArray.join(';'))
      setSelectedShippingMethods(shippingFeeUsdArray.join(';'))
      setAvailableShippingMethods(shippingMethodsForAllProducts);
    }
  }

  return (
    <>
      {openAddressModal && (
        <BillingAddressModel
          address={address}
          setAddress={setAddress}
          openAddressModal={openAddressModal}
          setOpenAddressModal={setOpenModal}
        />
      )}

      <SEO title="Checkout" />
      {orderStatus ? (
        <Redirect
          to={{
            pathname: '/order-success',
            state: {
              placedOrders: placedOrders
            },
          }}
        />
      ) : null}

      {orderFailed ? (
        <Redirect
          to={{
            pathname: '/order-failed',
            state: { id: orderInformation.id },
          }}
        />
      ) : null}

      <Layout>
        <>
          <div className="main-content">
            <div className="container-fluid px-5 ">
              <div className="rwt-contact-area mt--30 mb-5">
                <div className="">
                  <div className="row">
                    <div className="col-lg-8 col-sm-12">
                      {/* Address Error */}
                      {addressError && (
                        <Alert className="text-center mt-3" variant="danger">
                          Please Enter Delivery Information
                        </Alert>
                      )}

                      {/* Terms and conditions error */}
                      {termsError && (
                        <Alert className="text-center mt-3" variant="danger">
                          Please Accept Terms and Conditions
                        </Alert>
                      )}
                      {address && Object.keys(address).length > 0 ? (
                        <div>
                          <div className="content-block">
                            <div className='row'>
                              <div className='col-12'>
                                <div className='row'>
                                  <div className='col-12 d-flex justify-content-between'>
                                    <h4 className='mb-1' style={{ 'fontSize': '17px' }}> Billing Details </h4>
                                    <span title='Edit Address Details'>
                                      <a className='cursor-pointer' onClick={() => setOpenModal(true)}>
                                        <BiEdit color='red' size={20} />
                                      </a>
                                    </span>
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='col-12'>
                                    <p className="pTags">
                                      Name: {address.name}
                                    </p>
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='col-12'>
                                    <p className="pTags">
                                      Address: {address.address}
                                    </p>
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='col-12'>
                                    <p className="pTags">
                                      City: {address.city}
                                    </p>
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='col-12'>
                                    <p className="pTags">
                                      State: {address.state}
                                    </p>
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='col-12'>
                                    <p className="pTags">
                                      Zip: {address.zip}
                                    </p>
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='col-12'>
                                    <p className="pTags">
                                      Phone Number: {address.phone}
                                    </p>
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='col-12'>
                                    <p className="pTags">
                                      Country: {address.country}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="content-block d-flex justify-content-center cursor-pointer ">
                            <label
                              className="text-secondary cursor-pointer"
                              htmlFor="add-address"
                              onClick={() => setOpenModal(true)}
                            >
                              {' '}
                              Add New Delivery Address
                            </label>
                            <AiOutlinePlus className="mt-2 ml-2" color="gray" id="add-address" />
                          </div>
                        </>
                      )}
                      <div className=" selectAll ">
                        <div className="">
                          <form>
                            <div>
                              {cart &&
                                cart.product_title &&
                                cart.product_title.split(';').map((item, index) => {
                                  return (
                                    <ProductListing
                                      key={index}
                                      item={item}
                                      index={index}
                                      deleteProduct={deleteProduct}
                                      changeQuantity={changeQuantity}
                                      updateCart={updateCart}
                                      cart={cart}
                                      checkoutProp={true}
                                      shippingFeeUsd={shippingFeeUsd}
                                      selectedShippingMethods={selectedShippingMethods}
                                      availableShippingMethods={availableShippingMethods}
                                      address={address}
                                      setTotalShippingCost={setTotalShippingCost}
                                    />
                                  );
                                })}
                            </div>
                            <div className="mb-5 text-center form-group"></div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className=" col-lg-4 col-sm-12 ">
                      <div className="selectall p-4 pb-2">
                        <div className="row">

                          <h4 className="ml-4 " style={{ 'fontSize': '17px' }}>
                            Order Summary
                          </h4>

                        </div>
                        <div className="row justify-content-between">
                          <div className="col-6">
                            <p style={{ marginBottom: 13 }}>Sub-Total</p>
                          </div>
                          <div className="col-6">
                            {loader ? (
                              <Spinner animation="border" />
                            ) : (
                              <>
                                {cart && cart.multiplePrice ? (
                                  <p style={{ marginBottom: 13 }}>
                                    {cart && cart.currency
                                      ? cart.currency.split(';')[0] + '. '
                                      : null}

                                    {cart.multiplePrice
                                      .split(';')
                                      .map(Number)
                                      .reduce((a, b) => a + b)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  </p>
                                ) : null}
                              </>
                            )}
                          </div>
                        </div>
                        <div className="row justify-content-between">
                          <div className=" d-flex align-items-center col-6">
                            <p className="deliveryValue ">Delivery</p>
                          </div>
                          <div className=" d-flex align-items-center col-6 ">
                            Cash On Delivery
                          </div>
                        </div>

                        {/* Delivery Cost */}
                        <div className="row justify-content-between">
                          <div className="d-flex align-items-center col-6">
                            <p style={{ marginBottom: 13 }}>
                              <span className='mr-2'>
                                Shipping Fee
                              </span>
                              {/* e */}
                            </p>
                          </div>
                          <div className=" d-flex align-items-center col-6 ">
                            <p style={{ marginBottom: 13 }}>
                              {cart && cart.currency
                                ? cart.currency.split(';')[0] + '. '
                                : null}
                              {totalShippingCost
                                ?.split(';')
                                ?.map(Number)
                                ?.reduce((a, b) => a + b)
                                ?.toString()
                                ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </p>
                          </div>
                        </div>

                        <div>
                          <div className="d-flex mt-2">
                            <div ><input type="checkbox" checked={isChecked}
                              onChange={handleCheckboxChange} className="checkBoxStyle" style={{ position: 'relative', opacity: '100' }} /></div>
                            <div ><label className="text-muted label-style" style={{ marginTop: '8px', marginLeft: '3px', marginBottom: '0' }}>I accept the <a className="textDecorationUnderLine" target="_blank" href="https://saarcbazaar.com/terms-and-conditions">terms and conditions</a></label ></div>
                          </div>

                          {/* Place order button */}
                          <Button
                            to={{ pathname: '/checkout', state: { cart } }}
                            className="checkoutButton"
                            style={{ 'marginTop': '0px' }}
                            onClick={() => { submitOrders() }}
                          >
                            {refresh ? <Spinner animation="border" /> : 'Place Order'}
                          </Button>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Conta
          ct Area  */}
          </div>
        </>
      </Layout>
    </>
  );
}
