import React from 'react';
import { useEffect } from 'react';
import { get } from '../../utils/api_helper';
import { useState } from 'react';
import Slider from 'react-slick';

const Ads = () => {
  // const [homepageAdPath, setHomepageAdPath] = useState();
  // const [url, setUrl] = useState('#');
  const [homepageAds, setHomepageAds] = useState([]);
  const getAds = async () => {
    await get('/advertisement')
      .then((response) => {
        // const homepageAd1 = response.filter((ads) => ads.area == 'homepage_ad_1');
        const homepageAdsFiltered = response.filter((ads) => ads.area.includes('homepage_ad_'))

        if (homepageAdsFiltered && homepageAdsFiltered.length > 0) {
          for (let homepageAdvert of homepageAdsFiltered) {
            homepageAdvert.image_path = process.env.REACT_APP_SAARC_IMAGE_API + '/' + homepageAdvert.image_path.substring(12).replace(/\\/g, '/')
          }
        }

        setHomepageAds(homepageAdsFiltered);

        // if (homepageAd1 && homepageAd1.length > 0) {
        //   setHomepageAdPath(process.env.REACT_APP_SAARC_IMAGE_API + '/' + homepageAd1[0].image_path.substring(12).replace(/\\/g, '/'));
        //   setUrl(homepageAd1[0].advert_url);
        // }
      })
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    getAds();
  }, [])
  const bg_image = './images/split/ads.png';

  const BannerActivation = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 10000,
  };
  return (
    <>
      {/* {homepageAdPath && (
        <div className="container">
          <div className="mt-5 mb-5 ads-container" style={{ height: '180px', borderRadius: '10px' }}>
            <a href={url} target='_blank'>
              <img
                src={homepageAdPath}
                className="ads-img d-lg-block d-sm-none d-md-none d-xs-none"
                alt="advertisement banner"
                style={{objectFit: 'none'}}
              />
            </a>
          </div>
        </div>
      )} */}
      <Slider
      className="slider-area slider-style-4 variation-2 slider-dot rn-slick-dot rn-slick-arrow ads-img d-lg-block d-sm-none d-md-none d-xs-none"
      {...BannerActivation}
    >
      {/* First Slide */}
        {homepageAds && homepageAds.map((advert) => {
          return(
            <div key={advert.id} className="mt-5 mb-5 ads-container" style={{ height: '180px', borderRadius: '10px' }}>
            <a href={advert?.advert_url} target='_blank'>
              <img
                src={advert.image_path}
                alt="advertisement banner"
                style={{ width: '100%', height: '100%' }}
              />
            </a>
          </div>
        )})}

    </Slider>
    </>
  );
};
export default Ads;
