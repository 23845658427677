import React from 'react';
import { Link } from 'react-router-dom';
import footerSAARC from '../../data/footer/footerSAARC.json';
import { FiFacebook, FiTwitter } from 'react-icons/fi';
import CopyrightTwo from './CopyrightTwo';
import { AiFillYoutube } from 'react-icons/all';

const footerIntemOne = footerSAARC[1];
const footerIntemTwo = footerSAARC[2];
const footerIntemThree = footerSAARC[3];
const footerIntemFour = footerSAARC[4];
const footerIntemFive = footerSAARC[5];

const indexOneLink = footerIntemOne.quicklink;
const indexTwoLink = footerIntemTwo.quicklink;
const indexThreeLink = footerIntemThree.quicklink;
const indexFourFive = footerIntemFive.quicklink;

const FooterTwo = () => {
  const scrollToId = (e, id) => {
    e.preventDefault();
    if (id === 'sourcings') {
      window.location.pathname = '/add-sourcing-request';
    } else if (document.getElementById(id)) {
      document.getElementById(id).scrollIntoView({ block: 'center' });
    }
  };
  return (
    <>
      <footer className="rn-footer footer-style-default variation-two">
        {/* <CalltoActionSeven /> */}
        <div className="footer-top bg-color-footer">
          <div className="container">
            <div className="row">
              {/* Start Single Widget  */}
              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="rn-footer-widget">
                  <h4 className="title">{footerIntemOne.title}</h4>
                  <div className="inner">
                    <ul className="footer-link link-hover">
                      {indexOneLink.map((data, index) => (
                        <>
                          {`${data.url}` === 'news' ||
                          `${data.url}` === 'events' ||
                          `${data.url}` === 'sourcings' ? (
                            <>
                              <li
                                style={{ color: 'white', 'fontSize': '14px' }}
                                className="cursor-pointer"
                                onClick={(e) => scrollToId(e, `${data.url}`)}
                                key={index}
                              >
                                {`${data.text}`}
                              </li>
                            </>
                          ) : (
                            <>
                              {`${data.url}` === '/users/chamber-verification' ? (
                                <a
                                  style={{ color: 'white', 'fontSize': '14px' }}
                                  href="/users/chamber-verification"
                                >
                                  {data.text}
                                </a>
                              ) : (
                                <>
                                  <li key={index}>
                                    <Link to={`${data.url}`}>{data.text}</Link>
                                  </li>
                                </>
                              )}
                            </>
                          )}
                        </>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              {/* End Single Widget  */}

              {/* Start Single Widget  */}
              {/*<div className="col-lg-2 col-md-6 col-sm-6 col-12">*/}
              {/*  <div className="rn-footer-widget">*/}
              {/*    <div className="widget-menu-top">*/}
              {/*      <h4 className="title">{footerIntemTwo.title}</h4>*/}
              {/*      <div className="inner">*/}
              {/*        <ul className="footer-link link-hover">*/}
              {/*          {indexThreeLink.map((data, index) => (*/}
              {/*            <li key={index}>*/}
              {/*              <Link to={`${data.url}`}>{data.text}</Link>*/}
              {/*            </li>*/}
              {/*          ))}*/}
              {/*        </ul>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/* End Single Widget  */}

              {/* Start Single Widget  */}

              {/* End Single Widget  */}

              {/* Start Single Widget  */}
              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="rn-footer-widget">
                  <h4 className="title">{footerIntemFour.title}</h4>
                  <div className="inner">
                    <ul className="footer-link link-hover">
                      {indexTwoLink.map((data, index) => (
                        <>
                          {`${data.url}` !== 'users/ntb-reporting' ? (
                            <li key={index}>
                              <Link to={`${data.url}`}>{data.text}</Link>
                            </li>
                          ) : (
                            <a
                              style={{ color: 'white' }}
                              href={data.url}
                              className="cursor-pointer"
                            >
                              {`${data.text}`}
                            </a>
                          )}
                        </>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              {/* End Single Widget  */}
              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="rn-footer-widget">
                  <h4 className="title">{footerIntemThree.title}</h4>
                  <div className="inner">
                    <ul className="footer-link link-hover">
                      {indexThreeLink.map((data, index) => (
                        <li key={index}>
                          <Link to={`${data.url}`}>{data.text}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              {/* Start Single Widget  */}
              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="rn-footer-widget">
                  <h4 className="title">{footerIntemFive.title}</h4>
                  <div className="inner">
                    {/*<h6 className="subtitle">{footerIntemFive.subtitle}</h6>*/}
                    <div className="inner">
                      <ul className="footer-link link-hover">
                        {indexFourFive.map((data, index) => (
                          <li key={index}>
                            <Link to={`${data.url}`}>{data.text}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <ul className="social-icon social-default justify-content-start">
                      <li>
                        <a
                          className="fb"
                          href="//facebook.com/SAARC-Bazaar-104279258787117"
                          target="_blank"
                        >
                          <FiFacebook color="white" />
                        </a>
                      </li>
                      <li>
                        <a
                          className="twitter"
                          href="https://twitter.com/SAARCBazaar"
                          target={'_blank'}
                        >
                          <FiTwitter color="white" />
                        </a>
                      </li>
                      {/*<li>*/}
                      {/*  <Link className="insta" to="instagram.com">*/}
                      {/*    <FiInstagram color="white" />*/}
                      {/*  </Link>*/}
                      {/*</li>*/}
                      {/*<li>*/}
                      {/*  <Link className="linkdin" to="linkdin.com">*/}
                      {/*    <FiLinkedin color="white" />*/}
                      {/*  </Link>*/}
                      {/*</li>*/}
                      <li>
                        <a
                          href="https://www.youtube.com/channel/UCdnTy56mdogZofDnNhvaxOQ"
                          target="_blank"
                        >
                          <AiFillYoutube color="red" size={25} />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* End Single Widget  */}
            </div>
            <CopyrightTwo />
          </div>
        </div>
      </footer>
      {/*<ScrollTop />*/}
    </>
  );
};

export default FooterTwo;
