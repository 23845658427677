import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { BannerActivation } from '../../utils/script';
import { BsSearch, FaMapMarkerAlt } from 'react-icons/all';
import { get } from '../../utils/api_helper';
import { InputGroup, Form } from 'react-bootstrap';
import Select from 'react-select';
import { Button } from 'react-bootstrap';
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',
    height: '30px',
    padding: '5px',
    'font-size': '12px',
  }),
  indicatorsContainer: (prevStyle, state) => ({
    ...prevStyle,
    display: 'inline',
  }),

  input: (provided, state) => ({
    ...provided,
    margin: '-3px',
  }),
  control: (provided, state) => ({
    'background-color': 'white',
    'border-right': '0.5px solid #F2F2F2',
    'border-radius': '20px 0px 0px 20px',
    'text-align': 'center',
    'vertical-align': 'center',
    height: '60px',
    width: '100%',
    'font-size': '14px',
  }),
  singleValue: (provided, state) => {
    const transition = 'opacity 300ms';
    return { ...provided, transition };
  },
};

const HomepageSlider = () => {
  const BannerData = [
    {
      image: '/images/bg/slider2/1-maldives.webp',
      title: 'Bhutan',
      link: 'https://en.wikipedia.org/wiki/Bhutan',
      name: 'Paro Valley, Bhutan',
    },
    {
      image: '/images/bg/slider2/2.webp',
      title: 'Bangladesh',
      link: 'https://www.remotelands.com/travelogues/architecture-and-heritage-in-bangladesh/',
      name: 'Sixty Dome Mosque, Bangladesh',
    },
    {
      image: '/images/bg/slider2/3-bangladesh.webp',
      title: 'Nepal',
      link: 'https://ntb.gov.np/everest',
      name: 'Mt. Everest, Nepal',
    },
    {
      image: '/images/bg/slider2/6-nepal.webp',
      title: 'India',
      link: 'https://en.wikipedia.org/wiki/Pattadakal',
      name: 'Pattadakal, India',

    },
    {
      image: '/images/bg/slider2/pattadakal-monuments-172207.jpg',
      title: 'Mahamevnawa Buddhist Monastery',
      link: 'https://en.wikipedia.org/wiki/Mahamevnawa_Buddhist_Monastery',
      name: 'Kuenselphodrang, Thimphu, Bhutan',
    },
    {
      image: '/images/bg/slider2/raimond-klavins-GEg0fm-vmMg-unsplash.jpg',
      title: 'Hiran Minar ',
      link: 'https://en.wikipedia.org/wiki/Hiran_Minar ',
      name: 'Pakistan',
    },
    {
      image: '/images/bg/slider2/salman-ahmad-NgzdlZSmsTg-unsplash.jpg',
      title: 'Hatirjheel - Bangladesh',
      link: 'https://en.wikipedia.org/wiki/Hatirjheel',
      name: 'Hatirjheel - Bangladesh',

    },
    {
      image: '/images/bg/slider2/salman-preeom-mO1_udD5iCs-unsplash.jpg',
      title: 'The Pinnawela Elephant Orphanage - SriLanka',
      link: 'https://nationalzoo.gov.lk/elephantorphanage/',
      name: 'Sri-Lanka',
    },
    {
      image: '/images/bg/slider2/8.jpg',
      title: 'Hawa Mahal - India',
      link: 'https://en.wikipedia.org/wiki/Hawa_Mahal',
      name: 'Hawa Mahal - India',
    },
    {
      image: '/images/bg/slider2/9.jpg', title: 'Maldives',
      link: 'https://www.overwaterbungalows.net/water-villas-in-the-maldives',
      description: 'test',
      name: ' The Standard, Huruvalhi Maldives',

    },
  ];
  const [listings, setListings] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [searchtext, setSearchText] = useState('');
  const [searchOption, setSearchOption] = useState({ option: 'products' });
  const onSelectionChange = (value, action) => {
    setSearchOption({ [action.name]: value.value });
  };
  const options = [
    { value: 'suppliers', label: 'Suppliers' },
    { value: 'products', label: 'Products' },
  ];
  useEffect(() => {
    async function getProducts() {
      await get('/listings')
          .then((resp) => {
            setListings(resp);
          })
          .catch((err) => {
            console.error('error', err);
          });
    }
    async function getSuppliers() {
      await get('/companies')
          .then((resp) => {
            setSuppliers(resp);
          })
          .catch((err) => {
            console.error('error', err);
          });
    }
    getSuppliers();
    getProducts();
  }, []);

  const [activeTab, setActiveTab] = useState(0);
  const onChangeHandler = (text) => {
    let matches = [];
    if (text.length > 0) {
      if (searchOption.option === 'products') {
        matches = listings.filter((listings) => {
          const regex = new RegExp(`${text}`, 'gi');
          return listings.title.match(regex);
        });
      } else if (searchOption.option === 'suppliers') {
        matches = suppliers.filter((supplier) => {
          const regex = new RegExp(`${text}`, 'gi');
          return supplier.name.match(regex);
        });
      }
    } else {
      setSuggestions([]);
    }
    setSuggestions(matches);
    setSearchText(text);
  };
  const onSuggestHandler = (text) => {
    setSearchText(text);
    setSuggestions([]);
  };

  return (
      <>
        <Slider
            className="slider-area slider-style-4 variation-2 slider-dot rn-slick-dot rn-slick-arrow"
            {...BannerActivation}
            autoplay
            autoplaySpeed={5000}
        >
          {BannerData.map((data, index) => (
              <div key={index} className="single-slide">
                {/* add overlay by adding bg-overlay in classname below */}
                <div
                    className="bg_image"
                    style={{
                      height: '100vh',
                      backgroundImage: `url(${process.env.PUBLIC_URL} ${data.image})`,
                    }}
                />

                <div className="button-group slide-info">
                  <a
                      className="btn btn-lg btn-dark round"
                      target="_blank"
                      rel="noreferrer"
                      href={data.link}
                  >
                    <FaMapMarkerAlt /> {data.name || 'Info'}
                  </a>
                </div>
              </div>
          ))}
        </Slider>
        <div className="blog-search offset-sm-2 col-sm-8">
          <div>
            <form action="/search" onSubmit="/search">
              <InputGroup>
                <Select
                    name="option"
                    options={options}
                    styles={customStyles}
                    onChange={onSelectionChange}
                    defaultValue={{ label: 'Products', value: 'products' }}
                />
                <Form.Control
                    style={{ 'font-size': '14px', 'box-shadow': 'none !important' }}
                    autoComplete="off"
                    className="shadow-none"
                    type="text"
                    placeholder="Search Here..."
                    name="s"
                    onChange={(e) => onChangeHandler(e.target.value)}
                    value={searchtext}
                />
                <Button type="submit" className="btn-info" style={{ padding: '15px' }}>
                  <BsSearch size="18" />
                </Button>
              </InputGroup>
            </form>
            {suggestions &&
                suggestions.slice(0, 6).map((suggestion, i) => (
                    <div
                        className="suggestions ml--80 pl-5"
                        key={i}
                        onClick={() => onSuggestHandler(suggestion.title || suggestion.name)}
                    >
                      <span style={{ 'font-size': '12px' }}>{suggestion.title || suggestion.name}</span>
                    </div>
                ))}
          </div>

          <div className="tagline-homepage">Bringing Trade Closer to South Asia</div>
        </div>
          {/* End Slider Area  */}
        </>
        );
        };
        export default HomepageSlider;
