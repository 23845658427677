import React, { useEffect, useState } from 'react';
import SectionTitle from '../sectionTitle/SectionTitle';
import Slider from 'react-slick';
import RecommendedProducts from '../../components/blog/itemProp/RecommendedProducts';
import { get } from '../../utils/api_helper';
import { COMPANY_STATUS, PRODUCT_STATUS } from '../../utils';
const Products = () => {
  const [randomData, setRandomData] = useState([]);
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [saleProducts, setSaleProducts] = useState([]);
  const [newArrivals, setNewArrivals] = useState([]);
  const [verifiedSupplierProducts, setVerifiedSupplierProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const loop = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  useEffect(() => {
    setLoading(true);
    async function getProducts() {
      // Get random products
      await get('/listings/random')
        .then((resp) => {
          if (resp.status) {
            setRandomData(resp?.data);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.error('error fetching random products', err);
          setLoading(false);
        });

      // Get featured products
      await get('/listings/featured')
        .then((resp) => {
          if (resp.status) {
            setFeaturedProducts(resp?.data);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.error('error fetching featured products', err);
          setLoading(false);
        });

      // Get sale products
      await get('/listings/saleProducts')
      .then((resp) => {
        if (resp.status) {
          setSaleProducts(resp?.data?.saleProducts);
          setNewArrivals(resp?.data?.newArrivals);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error('error fetching featured products', err);
        setLoading(false);
      });

      // Get Verified Sellers Products
      // Get sale products
      await get('/listings/getVerifiedProducts')
      .then((resp) => {
        if (resp.status) {
          setVerifiedSupplierProducts(resp?.data?.verifiedListings);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error('error fetching featured products', err);
        setLoading(false);
      });
    }
    getProducts();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="blog-area mb--30">
      <div className="container">
        {/* Featured Products */}
        <div className="row">
          <div className="col-lg-12 pt--30">
            <SectionTitle titleLine="title-line" radiusRounded="" title="Featured Products" />
            {/*SHOWING FEATURED PRODUCTS FROM API*/}
            <Slider {...settings}>
              {featuredProducts &&
                featuredProducts.map((item) => (
                  <div key={item.id} className="col-12">
                    <RecommendedProducts StyleVar="box-card-style-default" data={item} />
                  </div>
                ))}
            </Slider>
          </div>
        </div>

        {/* Recommended Products */}
        <div className="row">
          <div className="col-lg-12 pt--30">
            <SectionTitle title="Recommended Products" titleLine="title-line" />
            {loading ? (
              <div className="spinner-custom" role="status">
                {loop.map((x) => (
                  <span className="sr-only-custom" />
                ))}
              </div>
            ) : (
              <Slider {...settings}>
                {randomData &&
                  randomData.map((item) => (
                    <>
                      {!item.featured ? (
                        <div key={item.id} className="col-12">
                          <RecommendedProducts StyleVar="box-card-style-default" data={item} />
                        </div>
                      ) : null}
                    </>
                  ))}
              </Slider>
            )}
          </div>
        </div>

        {/* Sale Products */}
        <div className="row">
          {saleProducts && saleProducts.length > 4 ? 
            <>
              {/* Sale Products */}
              <div className="col-lg-12 pt--30">
                <SectionTitle titleLine="title-line" radiusRounded="" title="Hot Sale" />
                {/*SHOWING FEATURED PRODUCTS FROM API*/}
                <Slider {...settings}>
                  {saleProducts &&
                    saleProducts.map((item) => (
                      <div key={item.id} className="col-12">
                        <RecommendedProducts StyleVar="box-card-style-default" data={item} />
                      </div>
                    ))}
                </Slider>
              </div>
            </>
          : null}

          {/* Verified Supplier's Prodcuts */}
          {verifiedSupplierProducts && verifiedSupplierProducts.length > 4 ? 
            <>
              {/* Sale Products */}
              <div className="col-lg-12 pt--30">
                <SectionTitle titleLine="title-line" radiusRounded="" title="Verified Suppliers" />
                {/*SHOWING FEATURED PRODUCTS FROM API*/}
                <Slider {...settings}>
                  {verifiedSupplierProducts &&
                    verifiedSupplierProducts.map((item) => (
                      <div key={item.id} className="col-12">
                        <RecommendedProducts StyleVar="box-card-style-default" data={item} />
                      </div>
                    ))}
                </Slider>
              </div>
            </>
          : null}
        </div>

        {/* New Arrivals */}
        <div className="row">
          {newArrivals && newArrivals.length > 4 ? 
            <>
              {/* Sale Products */}
              <div className="col-lg-12 pt--30">
                <SectionTitle titleLine="title-line" radiusRounded="" title="New Arrivals" />
                {/*SHOWING FEATURED PRODUCTS FROM API*/}
                <Slider {...settings}>
                  {newArrivals &&
                    newArrivals.map((item) => (
                      <div key={item.id} className="col-12">
                        <RecommendedProducts StyleVar="box-card-style-default" data={item} />
                      </div>
                    ))}
                </Slider>
              </div>
            </>
          : null}
        </div>
      </div>
    </div>
  );
};
export default Products;
