import React, { useEffect, useState } from 'react';
import SEO from '../common/SEO';
import HeaderOne from '../common/header/HeaderOne';
import SouringRequests from '../elements/split/SouringRequestForm';
import NewsAndEvents from '../elements/calltoaction/NewsAndEvents';
import FooterTwo from '../common/footer/FooterTwo';
import HomepageSlider from '../elements/homepage/HomepageSlider';
import Products from '../elements/homepage/Products';
import Ads from '../elements/split/Ads';
import FacebookPopup from '../elements/homepage/FacebookPopup';
import { useLocation } from 'react-router-dom';

const Home = (props) => {
  const [popup, setPopup] = useState(false);
  const [showSlider, setShowSlider] = useState(true);
  let query = useQuery();

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  useEffect(() => {
    if (props?.location?.state) {
      setPopup(props.location.state.popup);
    } else if (query.get('popup')) {
      setPopup(false);
    } else {
      setPopup(true);
    }
  }, []);

  return (
    <>
      <SEO title="SAARC Bazaar" />
      <main className="page-wrapper">
        <FacebookPopup showSlider={showSlider} setShowSlider={setShowSlider} popup={popup} />
        <HeaderOne btnStyle="btn-small btn-icon round" HeaderSTyle="header-transparent" showSlider={showSlider} />
        {showSlider && (
          <HomepageSlider/>
        )}
        <Products/>
        <SouringRequests/>
        <NewsAndEvents/>
        <Ads/>
        <FooterTwo/>
      </main>
    </>
  );
};

export default Home;
