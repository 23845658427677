import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop';
import bsCustomFileInput from 'bs-custom-file-input';

// Pages import Here
import Home from './pages/Home';

// Elements import Here

import Contact from './elements/contact/Contact';
import Error from './pages/Error';

// Import Css Here
import './assets/scss/style.scss';
import ProductDetails from './pages/ProductDetails';
import SourcingRequest from './elements/sourcingRequest/Contact';
import CompanyDetails from './pages/CompanyDetails';
import Search from './pages/Search';
import EventsList from './components/blog/EventsList';
import ViewSourcingRequest from './elements/sourcingRequest/ViewSourcingRequest';
import ViewPublications from './elements/publications/ViewPublications';
import ViewTradeItems from './elements/tradeItems/ViewTradeItems';
import ReactGa from 'react-ga4';
import Gallery from './elements/gallery/Gallery';
import ContactUs from './elements/Contact Us/ContactUs';
import Categories from './components/blog/Categories';
import PrivacyPolicy from './components/blog/PrivacyPolicy';
import TermsAndConditions from './components/blog/TermsAndConditions';
import Disclaimer from './components/blog/Disclaimer';
import Destinations from './elements/destinations/Destinations';
import TradeAgreements from './elements/tradeAgreements/TradeAgreements';
import TradeGuides from './elements/tradeAgreements/TradeGuides';
import TopTen from './elements/tradeAgreements/TopTen';
import EconomicStats from './elements/tradeAgreements/ExconomicStats';
import Cart from './pages/Cart';
import OrderSuccess from './pages/OrderSuccess';
import OrderFailed from './pages/OrderFailed';
import CheckOut from './pages/CheckOut';
import DataDeletionRequest from './components/blog/DataDeletionRequest';

const App = () => {
  // Google Analytics Integration
  useEffect(() => {
    ReactGa.initialize([
      {
        trackingId: 'G-C0WQJCWE75',
      },
    ]);

    ReactGa.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
  }, []);

  // Google Translator Integration
  useEffect(() => {
    bsCustomFileInput.init();
  }, []);

  //----translate----//
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
      },
      'google_translate_element'
    );
  };

  useEffect(() => {
    var addScript = document.createElement('script');
    addScript.setAttribute(
      'src',
      'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);



  return (
    <Router>
      <PageScrollTop>
        <Switch>
          <Route path={`${process.env.PUBLIC_URL + '/'}`} exact component={Home} />
          <Route
            path={`${process.env.PUBLIC_URL + '/order-success'}`}
            exact
            component={OrderSuccess}
          />
          <Route
            path={`${process.env.PUBLIC_URL + '/order-failed'}`}
            exact
            component={OrderFailed}
          />
          <Route path={`${process.env.PUBLIC_URL + '/cart'}`} exact component={Cart} />
          <Route path={`${process.env.PUBLIC_URL + '/contact'}`} exact component={Contact} />
          <Route
            path={`${process.env.PUBLIC_URL + '/add-sourcing-request'}`}
            exact
            component={SourcingRequest}
          />
          <Route
            path={`${process.env.PUBLIC_URL + '/view-sourcing-request/'}`}
            exact
            component={ViewSourcingRequest}
          />
          <Route path={`${process.env.PUBLIC_URL + '/checkout/'}`} exact component={CheckOut} />
          <Route
            path={`${process.env.PUBLIC_URL + '/publications'}`}
            exact
            component={ViewPublications}
          />
          <Route
            path={`${process.env.PUBLIC_URL + '/destinations'}`}
            exact
            component={Destinations}
          />{' '}
          <Route
            path={`${process.env.PUBLIC_URL + '/trade-agreements'}`}
            exact
            component={TradeAgreements}
          />
          <Route
            path={`${process.env.PUBLIC_URL + '/trade-guides'}`}
            exact
            component={TradeGuides}
          />
          <Route path={`${process.env.PUBLIC_URL + '/top-10s'}`} exact component={TopTen} />
          <Route
            path={`${process.env.PUBLIC_URL + '/economic-statistics'}`}
            exact
            component={EconomicStats}
          />
          <Route path={`${process.env.PUBLIC_URL + '/categories'}`} exact component={Categories} />
          <Route
            path={`${process.env.PUBLIC_URL + '/privacy-policy'}`}
            exact
            component={PrivacyPolicy}
          />
          <Route
            path={`${process.env.PUBLIC_URL + '/terms-and-conditions'}`}
            exact
            component={TermsAndConditions}
          />
          <Route
            path={`${process.env.PUBLIC_URL + '/data-deletion-request'}`}
            exact
            component={DataDeletionRequest}
          />
          <Route path={`${process.env.PUBLIC_URL + '/disclaimer'}`} exact component={Disclaimer} />
          <Route
            path={`${process.env.PUBLIC_URL + '/trade-items'}`}
            exact
            component={ViewTradeItems}
          />
          <Route
            path={`${process.env.PUBLIC_URL + '/product-details/:id'}`}
            exact
            component={ProductDetails}
          />
          <Route
            path={`${process.env.PUBLIC_URL + '/product-details/:preview/:id'}`}
            exact
            component={ProductDetails}
          />
          <Route path={`${process.env.PUBLIC_URL + '/contact-us'}`} exact component={ContactUs} />
          <Route
            path={`${process.env.PUBLIC_URL + '/company-details/:id'}`}
            exact
            component={CompanyDetails}
          />
          <Route
            path={`${process.env.PUBLIC_URL + '/company-details/:preview/:id'}`}
            exact
            component={CompanyDetails}
          />
          <Route
            exact
            path="/users/login"
            render={() => (window.location = '//saarcbazaar.com/users/login')}
          />
          <Route
            exact
            path="/users/register"
            render={() => (window.location = '//saarcbazaar.com/users/register')}
          />
          <Route path={`${process.env.PUBLIC_URL + '/search'}`} exact component={Search} />
          {/* News and Events */}
          <Route
            path={`${process.env.PUBLIC_URL + '/events-and-exhibitions'}`}
            exact
            component={EventsList}
          />
          {/* <Route path={`${process.env.PUBLIC_URL + '/gallery'}`} exact component={Gallery} /> */}
          <Route path={`${process.env.PUBLIC_URL + '*'}`} exact component={Error} />
        </Switch>
      </PageScrollTop>
    </Router>
  );
};

export default App;
