import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import * as api from '../../utils/api_helper';
import { getUserId, isLoggedIn } from '../../utils';
import { InputGroup, Spinner } from 'react-bootstrap';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/all';
import { useHistory } from 'react-router-dom';


function CartModel(props) { // props has whole product
  console.log('price', props.convertedPrice)
  const isUserLoggedIn = isLoggedIn();
  const [cart, setCart] = useState({});
  const [qty, setQty] = useState('1');
  const history = useHistory();
  const [currency, setCurrency] = useState(localStorage.getItem('cur'));
  const userId = getUserId();
  const [price, setPrice] = useState(props.convertedPrice);
  const [discountedPrice, setDiscountedPrice] = useState(props.discountedPrice);

  // const sendVariationtoCart = () => {
  //   if (props.variationFilteredData) {
  //     history.push({
  //       pathname: '/cart',
  //       state: { data: props.variationFilteredData }
  //     });
  //   }

  // };

  const handleClose = () => {
    props.setShowCartModal(false);
  };

  // const onQuantityChange = (e) => {
  //   setQty(e.target.value);
  // };

  const changeQuantity = async (change) => {
    if (change === 'add') {
      const quantity = (parseInt(qty) + 1);
      if (discountedPrice && discountedPrice > 0) {
        setDiscountedPrice(props.discountedPrice * quantity);
      } else if (price && price > 0) {
        setPrice(props.convertedPrice * quantity);
      }
      setQty(quantity.toString());
    } else if (change === 'subtract') {
      if (qty == 1) {
        // Can not be done
      } else {
        const quantity = (parseInt(qty) - 1);
        if (discountedPrice && discountedPrice > 0) {
          setDiscountedPrice(props.discountedPrice * quantity);
        } else if (price && price > 0) {
          setPrice(props.convertedPrice * quantity);
        }
        setQty(quantity.toString());
      }
    }
  };

  const addToCart = () => {
    // If user is not logged in
    if (!isUserLoggedIn) {
      history.push('/users/login');
      return;
    }

    // If cart exist
    if (cart) {
      //This is country of user
      cart.country = cart.country ? cart.country.concat(';', props.country) : props.country;
      props.setCartLoading(true);

      cart.product_title = cart.product_title ? cart.product_title.concat(';', props.productItem.title) : props.productItem.title;
      cart.qty = cart.qty ? cart.qty.concat(';', qty) : qty.toString();

      //this code is written to cater variations
      if (discountedPrice) {
        cart.price = cart.price ? cart.price.concat(';', discountedPrice.toString()) : discountedPrice.toString();
      } else {
        cart.price = cart.price ? cart.price.concat(';', price) : price.toString();
      }

      // cart.price = cart.price ? cart.price.concat(';', props.convertedPrice) : props.convertedPrice;
      cart.product_id = cart.product_id
        ? cart.product_id.concat(';', props.productItem.id)
        : props.productItem.id;
      cart.supplier_id = cart.supplier_id
        ? cart.supplier_id.concat(';', props.productItem.Company.id)
        : props.productItem.Company.id;
      cart.supplier_name = cart.supplier_name
        ? cart.supplier_name.concat(';', props.productItem.Company.name)
        : props.productItem.Company.name;
      cart.currency = cart.currency ? cart.currency.concat(';', currency) : currency;

      if (props?.variationFilteredData && props.variationFilteredData[0]?.attachment) {
        cart.image = cart.image
          ? cart.image.concat(';', props.variationFilteredData[0].attachment?.replace('attachments', '')?.replace(';', ''))
          : props.variationFilteredData[0].attachment?.replace('attachments', '')?.replace(';', '');
      }
      else if (props.attachments && props.attachments[0] && props.attachments[0]?.path != '') {
        cart.image = cart.image
          ? cart.image.concat(';', props.attachments[0].path.replaceAll(';', ''))
          : props.attachments[0].path.replaceAll(';', '');
      } else if (props?.image && props?.image[1] && props.image[1].path != '') {
        cart.image = cart.image
          ? cart.image.concat(';', props.attachments[1].path.replaceAll(';', ''))
          : props.attachments[1].path.replaceAll(';', '');
      } else {
        cart.image = '';
      }


      // Get variation id
      let variation_id = '0';
      if (props?.variationFilteredData && props.variationFilteredData[0]?.id) {
        variation_id = props.variationFilteredData[0].id;
      }

      // check if we have variations in cart
      if (cart.variation_id) {
        cart.variation_id = cart.variation_id.concat(';', variation_id)
      } else {
        cart.variation_id = variation_id;
      }

      // Handle weights for calculating shipping costs
      let weightPerProduct = 0;
      let totalWeight = 0;
      if (props.productItem.weight_per_carton && props.productItem.weight_per_carton > 0) {
        weightPerProduct = parseFloat(props.productItem.weight_per_carton);
        totalWeight = parseFloat(weightPerProduct * qty)
      }

      if (cart.weight_per_item) {
        cart.weight_per_item = cart.weight_per_item.concat(';', weightPerProduct);
      } else {
        cart.weight_per_item = weightPerProduct.toString();
      }

      if (cart.package_weight) {
        cart.package_weight = cart.package_weight.concat(';', totalWeight);
      } else {
        cart.package_weight = totalWeight.toString();
      }

      // let shippingCost = '0';
      // if (cart.shipping_cost_usd) {
      //   cart.shipping_cost_usd = cart.shipping_cost_usd.concat(';', shippingCost);
      // } else {
      //   cart.shipping_cost_usd = shippingCost;
      // }

      api
        .put('/cart/' + cart.id, cart)
        .then((response) => {
          setCart(response);
          props.setCartLoading(false);
          props.setShowCartModal(false);
          props.setUpdateCart(props.updateCat + 1);
        })
        .catch((error) => {
          props.setCartLoading(false);
          props.setShowCartModal(false);
        });
    } else {
      alert('Cart does not exist')
    }
  };

  useEffect(() => {
    if (userId) {
      api
        .get('/cart/user/' + userId)
        .then((response) => {
          setCart(response[0]);
        })
        .catch((error) => console.error('Error fetching cart', error));
    }
  }, []);


  return (
    <>
      <Modal show={props.showCartModal} onHide={handleClose}>
        <div className="padding-left-right">
          <Modal.Header closeButton>
            <Modal.Title>Add to Cart</Modal.Title>
          </Modal.Header>
          <Modal.Body className="padding-left-right">
            <div className="row">
              <div className="col-lg-4">
                {props?.variationFilteredData && Object.keys(props.variationFilteredData).length > 0 ?
                  <img
                    src={process.env.REACT_APP_SAARC_IMAGE_API + '/' + props.variationFilteredData.attachment?.replace('attachments', '')?.replace(';', '')}
                    width="300"
                    height="300"
                    alt="product-image"
                  />
                  :
                  <>
                    {console.log(props)}
                    {props.image && props.image[0] && props.image[0].path != '' ? (
                      <img
                        src={`${process.env.REACT_APP_SAARC_IMAGE_API}/${props.image[0].path.substring(12).replace(';', '')}`}
                        width="300"
                        height="300"
                        alt="product-image"
                      />
                    ) : 
                      <>
                        {props?.image && props?.image[1] && props?.image[1].path != '' ?
                          <img
                            src={`${process.env.REACT_APP_SAARC_IMAGE_API}/${props.image[1].path.substring(12).replace(';', '')}`}
                            width="300"
                            height="300"
                            alt="product-image"
                          />
                          : ('No Image Available')
                        }
                      </>
                    }
                  </>
                }
              </div>
              <div className="col-lg-8">
                <div className="row mb-2">
                  <div className="col">
                    <label>{props.productItem.title}</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <label>
                      <strong>Price:</strong>{' '}
                      {discountedPrice && discountedPrice > 0 && !isNaN(discountedPrice) ? (
                        <span>{currency + discountedPrice}</span>
                      ) : (
                        <span>{price && !isNaN(price) ? currency + price : 'N/A'}</span>
                      )}
                    </label>
                  </div>
                </div>
                {price && !isNaN(price) && (
                  <div className="row mb-3">
                    <div className="col">
                      <label className="row">
                        <strong className="col-3 mt-2">Quantity</strong>
                        <div className="col-6 d-flex align-items-center">
                          <AiOutlineMinus
                            className="hover-dark mr-3 bg-white"
                            size={20}
                            onClick={() => changeQuantity('subtract')}
                          />
                          <input
                            type="text"
                            style={{ width: '18px', height: '35px', border: 'none' }}
                            min={1}
                            className="pl-0 pr-0"
                            value={qty}
                            readOnly={true}
                          />
                          <AiOutlinePlus
                            className="cursor bg-white hover-dark"
                            size={20}
                            onClick={() => changeQuantity('add')}
                          />
                        </div>
                      </label>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-lg" variant="danger" onClick={handleClose}>
              Cancel
            </Button>
            {props.convertedPrice ?
              <>
                <Button
                  className="btn btn-lg"
                  variant="primary"
                  onClick={addToCart}
                  disabled={!props.convertedPrice}
                >
                  {props.cartLoading ? <Spinner animation="border" size="sm" /> : 'Add to Cart'}
                </Button>
              </>
              :
              <>
                <Button
                  className="btn btn-lg"
                  variant="primary"
                  onClick={() => props.setShowQueryModal(true)}
                >
                  Ask for Price
                </Button>
              </>
            }
          </Modal.Footer>
        </div>{' '}
      </Modal>
    </>
  );
}

export default CartModel;
