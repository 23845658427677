import React, { useEffect, useState } from 'react';
import * as api from '../utils/api_helper';
import { getAllUsers, getUserId, getUserInformationFromCompanyId } from '../utils';
import { Launcher } from 'react-chat-window';
import ChatWindow from 'react-chat-window/lib/components/ChatWindow';

const SendNewMessage = ({newMessage, setNewMessage, companyId='', companyName='', username='' }) => {
  const [newMessageModal, setNewMessageModal] = useState(!!username);
  const [allUsers, setAllUsers] = useState('');
  const [refetch, setRefetch] = useState(0);
  const [errorMsg, setErrorMsg] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const userId = getUserId();

  useEffect(async () => {
    if(companyId != '') {
      const userInfo = await getUserInformationFromCompanyId(companyId);
      if (userInfo.success) {
        setUserDetails(userInfo.user);
        console.log("userInfo.user", userInfo.user)
        await getChatMessages(userInfo.user.username);
      }
    }
  }, [])

  // Handle close
  const handleClose = () => {
    setIsOpen(false);
    setNewMessage(false);
  };

  useEffect(async () => {
    await getChatMessages(userDetails.username)
  }, [refetch]) 

  const getChatMessages = async (username) => {
    return new Promise((resolve, reject) => {
      api
        .get('/messages/all/' + username)
        .then((resp) => {
          resp.map((item) => {
            delete item['id'];

            if (item.fromId === userId) {
              item.author = 'me';
            } else {
              item.author = 'them';
            }

            item.data = { text: item.text };
            item.type = 'text';
            delete item['toId'];
            delete item['fromId'];
            delete item['createdAt'];
            delete item['updatedAt'];
            delete item['text'];
          });
          setMessages(resp);
          resolve(resp);
        })
        .catch((error) => reject(error));
    });
  };

  const handleSubmit = (message) => {
    if (!message.data.text) return;
    message.data.selectedUser = userDetails.id;
    message.data.messageText = message.data.text;
    handlingSend(message.data);
  };

  const sendMessage = async ({ selectedUser: toId, messageText: text }) => {
    return new Promise((resolve, reject) => {
      api
        .post('/messages', { toId: toId, text: text, fromId: userId })
        .then((resp) => resolve(resp))
        .catch((error) => reject(error));
    });
  };

  const handlingSend = async (message) => {
    await sendMessage(message)
      .then((resp) => {
        onUpdate();
      })
      .catch((e) => {
        setErrorMsg(e.response.data.message);
        console.error('Error sending message:', e);
        onUpdate();
      });
  };

  const onUpdate = () => {
    setRefetch(refetch + 1);
  };

  function toggle_new_message() {
    setNewMessageModal(!newMessageModal);
    setNewMessage(false);
  }

  return (
    <>
      {isOpen ? (
        <>
          <ChatWindow
            className="react-chat"
            agentProfile={{ teamName: companyName}}
            messageList={messages}
            showEmoji={false}
            // onFilesSelected={false}
            onClose={handleClose}
            isOpen={true}
            onUserInputSubmit={handleSubmit}
          />
        </>
      ) : null}
    </>
  );
};
export default SendNewMessage;
