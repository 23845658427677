import React from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
const FAQs = (props) => {
  return (
    <Accordion className={`rn-accordion-style ${props.customStyle}`}>
      {props?.faqs?.questionOne ? (
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0">
              {props.faqs.questionOne}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>{props.faqs.answerOne}</Card.Body>
          </Accordion.Collapse>
        </Card>
      ) : null}

      {props?.faqs?.questionTwo ? (
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="1">
              {props.faqs.questionTwo}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>{props.faqs.answerTwo}</Card.Body>
          </Accordion.Collapse>
        </Card>
      ) : null}

      {props?.faqs?.questionThree ? (
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="2">
              {props.faqs.questionThree}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="2">
            <Card.Body>{props.faqs.answerThree}</Card.Body>
          </Accordion.Collapse>
        </Card>
      ) : null}

      {props?.faqs?.questionFour ? (
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="3">
              {props.faqs.questionFour}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="3">
            <Card.Body>{props.faqs.answerFour}</Card.Body>
          </Accordion.Collapse>
        </Card>
      ) : null}

      {props?.faqs?.questionFive ? (
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="4">
              {props.faqs.questionFive}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="4">
            <Card.Body>{props.faqs.answerFive}</Card.Body>
          </Accordion.Collapse>
        </Card>
      ) : null}
    </Accordion>
  );
};
export default FAQs;
