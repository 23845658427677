import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getUserId } from '../../utils';
import * as api from '../../utils/api_helper';

function BillingAddressModel({ address, setAddress, openAddressModal, setOpenAddressModal }) {
  const [temperoryAddress, setTemperoryAddress] = useState(address);
  const userId = getUserId();

  // Submit delivery address to be save in the db
  const submitDeliverAddress = async (e) => {
    e.preventDefault();
    // If address already exist in the db then put new address
    if (address?.id && address?.UserId == userId) {
      await api.put(`/addresses/${address.id}`, temperoryAddress)
        .then((response) => {
          setAddress(response);
          setOpenAddressModal(false);
        })
        .catch((error) => console.log(error));
    } else {
      temperoryAddress.UserId = userId;
      if  (temperoryAddress.UserId) {
        await api.post(`/addresses/add`, temperoryAddress)
        .then((response) => {
          if (response.success) {
            setAddress(response.address);
            setOpenAddressModal(false);
          }
        })
        .catch((error) => console.log(error))
      } else {
        console.log("Missing user id")
      }
    }
  }

  const onChange = (e) => {
    setTemperoryAddress({ ...temperoryAddress, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Modal
        show={openAddressModal}
        onHide={() => setOpenAddressModal(false)}
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
      >
        <Modal.Header closeButton>
          <div>
            {' '}
            <p className="m-0 pt-2">Add new Billing Address</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={(e) => submitDeliverAddress(e)}>
            <div className="p-4">
              <div className="row">
                <div className="col-6">
                  <div className="col-12">
                    <label className="m-0 ">Full Name </label>
                    <input
                      value={temperoryAddress.name}
                      className="p-2 mb-4 input-form"
                      onChange={onChange}
                      name="name"
                      placeholder="Input Full Name"
                      required={true}
                      type="text"
                    />
                  </div>
                  <div className="col-12">
                    <label className="m-0">Address</label>
                    <input
                      value={temperoryAddress.address}
                      className="p-2 mb-4 input-form"
                      name="address"
                      onChange={onChange}
                      placeholder="House no. / Building / street / area"
                      required={true}
                      type="text"
                    />
                  </div>
                  <div className="col-12">
                    <label className="m-0">State</label>
                    <input
                      value={temperoryAddress.state}
                      className="p-2 mb-4 input-form"
                      name="state"
                      onChange={onChange}
                      placeholder="Your state name"
                      required={true}
                      type="text"
                    />
                  </div>
                  
                  <div className="col-12">
                    <label className="m-0">Country</label>
                    <input
                      value={temperoryAddress.country}
                      className="p-2 mb-4 input-form"
                      onChange={onChange}
                      name="country"
                      placeholder="Please choose your country"
                      required={true}
                      type="text"
                    />
                  </div>
                  
                  <div className="col-12">
                    <label className="m-0">Email Address</label>
                    <input
                      value={temperoryAddress.email}
                      className="p-2 mb-4 input-form"
                      onChange={onChange}
                      placeholder="Email"
                      name="email"
                      required={true}
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-6">
                <div className="col-12">
                    <label className="m-0">Mobile Number </label>
                    <input
                      value={temperoryAddress.phone}
                      className="p-2 mb-4 input-form"
                      onChange={onChange}
                      name="phone"
                      placeholder="Input mobile number"
                      required={true}
                      type="text"
                    />
                  </div>
                  <div className="col-12">
                  <label className="m-0">City </label>
                    <input
                      value={temperoryAddress.city}
                      className=" p-2 mb-4 input-form"
                      onChange={onChange}
                      name="city"
                      placeholder="Please choose your city/municipilty"
                      required={true}
                      type="text"
                    />
                  </div>
                  <div className="col-12">
                    <label className="m-0">Zip</label>
                    <input
                      value={temperoryAddress.zip}
                      className="p-2 mb-4 input-form"
                      onChange={onChange}
                      placeholder="Postal Code"
                      name="zip"
                      required={true}
                      type="text"
                    />
                  </div>
                  <div className="col-12">
                    <label className="m-0">Landmark(Optional)</label>
                    <input
                      value={temperoryAddress.landmark}
                      className="p-2 mb-4 input-form"
                      onChange={onChange}
                      placeholder="E.g beside train station"
                      name="landmark"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div>
                <Button
                  className="float-right p-3 pl-5 pr-5 "
                  variant="success"
                  type='submit'
                >
                  Save
                </Button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default BillingAddressModel;
