import React, {useEffect, useState} from 'react';
import { AiOutlineMinus, AiOutlinePlus, BiTrash, GoHeart } from 'react-icons/all';
import * as api from '../../utils/api_helper';
import {BsHeart, BsHeartFill} from "react-icons/bs";
import {get, put} from "../../utils/api_helper";
import {isLoggedIn} from "../../utils";
import ShippingCostModal from './ShippingCostModal';

export default function ProductListing({item, index, selectProduct, deleteProduct, changeQuantity, cart, isCheckBox, checkoutProp, variation, shippingFeeUsd, selectedShippingMethods, availableShippingMethods, address, setTotalShippingCost }) {
    const [refetch, setRefetch] = useState(false);
    const [checkboxLoading, setCheckboxLoading] = useState(false);
    const [colour, setColor] = useState(false);
    const [showBsHeart, setShowBsHeart] = useState(true);
    const [addedToWishlist, setAddedToWishlist] = useState([]);
    const [wishlist, setWishlist] = useState();
    const [showShippingCostModal, setShowShippingCostModal] = useState(false);
    const [shippingFee, setShippingFee] = useState('');
    const loggedIn = isLoggedIn();

    const toggleHeartIcon = () => {
        setShowBsHeart((prev) => !prev);
    };

    useEffect(() => {
        setShippingFee(shippingFeeUsd)
    }, [shippingFeeUsd])

    useEffect(() => {
        async function getWishlist() {
            await get('/wishlist/user/' + cart.UserId)
                //this useEffect gets all the wishlist and then sees if id matches and if it does setAddedToWishlist is trye
                .then((resp) => {
                    let cartProductArray = cart.product_id.split(';');
                    let wishlistArray = [];
                    setWishlist(resp[0]);
                    for (let index in cartProductArray) {
                        if (resp[0]?.productIds.split(';').find((id) => id === cartProductArray[index])) {
                            wishlistArray.push(true)
                        } else {
                            wishlistArray.push(false)
                        }
                    }
                    setAddedToWishlist(wishlistArray);
                })
                .catch((err) => console.error('Error getting wishlist', err));
        }
        getWishlist();
    }, [setRefetch]);

    const addToWishlist = async (productId, index) => {
        if (!loggedIn) {
            window.location = process.env.REACT_APP_SAARC_DEFAULT_URL + '/users/login';
        } else {
            if (wishlist)
                await put('/wishlist/product/' + wishlist.id, {productIds: productId})
                    .then((resp) => {
                        let tempWishlistStaus = addedToWishlist;
                        tempWishlistStaus[index] = true;
                        setAddedToWishlist(tempWishlistStaus);
                        setRefetch(!refetch)
                    })
                    .catch((err) => {
                        console.error('Could not add to wishlist', err);
                    });
        }
    };

    // Remove from wishlist
    const removeFromWishlist = async (productId, index) => {
        let updatedIDs = wishlist.productIds;
        updatedIDs = updatedIDs.replace(productId, '').replace(/(^;)/g, '');

        await put('/wishlist/delete/' + wishlist.id, {productIds: updatedIDs})
            .then(() => {
                let tempWishlistStaus = addedToWishlist;
                tempWishlistStaus[index] = false;
                setAddedToWishlist(tempWishlistStaus);
                setRefetch(!refetch);
            })
            .catch((err) => console.error('Error updating wishlist', err));
    };

    const changeShippingFee = async () => {
        setShowShippingCostModal(true);
    }

    // On click on specific fee, update selected attribute in the local variable
    const handleShippingCostChange = (selectedFeeIndex) => {
        let numProcessed = 0;
        let newFee = {};
        for (let fee of availableShippingMethods[index]) {
            if (numProcessed == selectedFeeIndex) {
                fee.selected = true;
                newFee = fee;
            } else {
                fee.selected = false;
            }
            numProcessed+=1;
        }

        // Update fee in local fee arrays
        const tempShippingCost = shippingFee.split(';');
        tempShippingCost[index] = newFee.convertedFee;
        setShippingFee(tempShippingCost.join(';'));

        // Update fees in cart for backend
        let cartShipping = cart.shipping_cost_usd.split(';');
        cartShipping[index] = newFee.convertedFee;
        cart.shipping_cost_usd = cartShipping.join(';');
        setTotalShippingCost(cartShipping.join(';'));

        let cartShippingMethods = cart.shipping_methods.split(';');
        cartShippingMethods[index] = newFee.name;
        cart.shipping_methods = cartShippingMethods.join(';');

        setShowShippingCostModal(false);
    }

    return (
        <>
            {/* Open modal to let user choose the shipping cost */}
            {showShippingCostModal && (
                <ShippingCostModal
                    show={showShippingCostModal}
                    handleClose={() => setShowShippingCostModal(false)}
                    shippingCosts={availableShippingMethods[index]}
                    handleShippingCostChange={handleShippingCostChange}
                    address={address}
                />
            )}

            {/*This is Checkout Page UI*/}
            {checkoutProp ? (
                cart && cart.status.split(';')[index] === 'true' ? (
                    <>
                        <div>
                            <div className="d-flex justify-content-start border-bottom mt-3 selectall p-2 ">
                                <label htmlFor={'supplier_name' + index}>
                                    {' '}
                                    {cart && cart.supplier_name ? cart.supplier_name.split(';')[index] + '. ' : null}
                                </label>
                            </div>

                            <div className="row selectall ">
                                <div className="col-lg-2 col-sm-12 d-flex justify-content-end">
                                    <img
                                        src={
                                            cart?.image?.split(';')[index]
                                            && process.env.REACT_APP_SAARC_IMAGE_API +
                                            '/' +
                                            cart.image.split(';')[index].replace('attachments', '')
                                        }
                                        alt="Product Image"
                                        className="image-product"
                                    />

                                </div>
                                <div className=" col-lg-4 mt-2 col-sm-12">
                                    <h5 className="h5-heading d-flex justify-content-center">Product Detail</h5>
                                    <h5
                                        className="text-info d-flex justify-content-center h5-heading "
                                        style={{fontSize: '1.7'}}
                                    >
                                        {' '}
                                        <a
                                            href={
                                                cart && cart.product_id
                                                    ? process.env.REACT_APP_SAARC_DEFAULT_URL +
                                                    '/product-details/' +
                                                    cart.product_id.split(';')[index]
                                                    : ''
                                            }
                                            target="_blank"
                                            className="d-flex justify-content-center"
                                        >
                                            {item}
                                        </a>
                                    </h5>
                                </div>
                                <div className="  mt-2 col-lg-3 col-sm-12 ">
                                    <h5 className=" h5-heading d-flex justify-content-center ">Quantity</h5>
                                    <p className="text-dark d-flex justify-content-center">
                                        {cart && cart.qty ? cart.qty.split(';')[index] : ''}
                                    </p>
                                </div>
                                <div className="mt-2 col-lg-3 col-sm-12">
                                    <h5 className="d-flex justify-content-center h5-heading">Total Price</h5>
                                    <div className="d-flex justify-content-center h5-heading mb-0">
                                        <label className='mb-0'>
                                            {cart && cart.currency ? cart.currency.split(';')[index] + '. ' : null}
                                            {cart && cart.price && cart.qty
                                            ? (parseInt(cart.price.split(';')[index]) * parseInt(cart.qty.split(';')[index])).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                            : null}
                                        </label>
                                    </div>

                                    {/* Shipping fees area */}
                                    {cart?.country?.split(';')[index] == 'Bangladesh' && (
                                        <>
                                            {shippingFee.split(';')[index] && shippingFee.split(';')[index] > 0
                                                ?
                                                <div className="d-flex justify-content-center mb-2 fs-6">
                                                    <a
                                                        className='mb-2 fs-7 text-muted cursor-pointer'
                                                        onClick={() => changeShippingFee()}
                                                    >
                                                        +Shipping {localStorage.getItem('cur')}. {shippingFee.split(';')[index]}
                                                    </a>
                                                </div>
                                                :
                                                <div className="d-flex justify-content-center mb-2 fs-6">
                                                    <span>Shipping: N/A</span>
                                                </div>
                                            }
                                        </>
                                    )}


                                    <div className="d-flex justify-content-center mb-2">
                                        {/*Checkout Page Heart*/}

                                        {showBsHeart ? (
                                            <BsHeart color="#DC1A22" size={22} className=" mr-1 cursor-pointer"
                                                     onClick={toggleHeartIcon}/>
                                        ) : (
                                            <GoHeart color="#DC1A22" size={26} className=" cursor-pointer"
                                                     onClick={toggleHeartIcon}/>
                                        )}
                                        <BiTrash
                                            size={23}
                                            color="red"
                                            className="cursor-pointer"
                                            onClick={() => deleteProduct(index)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : null
            ) : (
                <>
                    {/*This is Cart Page UI*/}
                    <div>
                        <div className="d-flex justify-content-start border-bottom mt-3 selectall p-2 ">
                            <label htmlFor={'supplier_name' + index}>
                                {' '}
                                {cart && cart.supplier_name ? cart.supplier_name.split(';')[index] + '. ' : null}
                            </label>
                        </div>

                        <div className="row selectall ">
                            <div className="  mt-5 col-lg-1 col-sm-12  ">
                                {isCheckBox && (
                                    <div className="form-check d-flex justify-content-center mb-2">
                                        <input
                                            className="form-check-input me-2"
                                            type="checkbox"
                                            id={'itemCheckbox' + index}
                                            name="selectAllCheckBox"
                                            checked={checkboxLoading ? false : cart.status.split(';')[index] === 'true'}
                                            onClick={() => selectProduct(index)}
                                        />
                                        <label htmlFor={'itemCheckbox' + index}></label>
                                    </div>
                                )}
                            </div>
                            <div className="col-lg-2 col-sm-12">
                                <img
                                    src={
                                        cart && cart.image && cart.image.split(';')[index]
                                            ? process.env.REACT_APP_SAARC_IMAGE_API +
                                            '/' +
                                            cart.image.split(';')[index].replace('attachments', '')
                                            : ''
                                    }
                                    alt="Product Image"
                                    className="image-product"
                                />


                            </div>
                            <div className=" col-lg-4 mt-5 col-sm-12">
                                <h5 className="text-info " style={{fontSize: '1.7'}}>
                                    {' '}
                                    <a
                                        href={
                                            cart && cart.product_id
                                                ? process.env.REACT_APP_SAARC_DEFAULT_URL +
                                                '/product-details/' +
                                                cart.product_id.split(';')[index]
                                                : ''
                                        }
                                        target="_blank"
                                    >
                                        {item}
                                    </a>
                                </h5>
                            </div>
                            <div className="  mt-5 col-lg-2 col-sm-12 ">
                                {/*<p className="mb-3">*/}{' '}
                                {cart && cart.currency ? cart.currency.split(';')[index] + '. ' : null}
                                {cart && cart.price && cart.qty

                                    // ? parseInt(cart.price.split(';')[index])  make this parseInt after testing
                                    ? (cart.price.split(';')[index])
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                    : null}
                                {/*</p>*/}
                                <div className="d-flex justify-content-center">
                                    {/*Cart Page*/}
                                    <div>
                                        {addedToWishlist && addedToWishlist.length > 0
                                            ?
                                            addedToWishlist[index] === false ? (
                                                    <>
                                                        <BsHeart
                                                            color="#DC1A22"
                                                            onClick={() => { addToWishlist(cart?.product_id?.split(';')[index], index)} }
                                                            size={22} className=" mr-1 cursor-pointer"
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <GoHeart
                                                            color="#DC1A22"
                                                            onClick={() => { removeFromWishlist(cart?.product_id?.split(';')[index], index)} }
                                                            size={28}
                                                            className=" cursor-pointer"
                                                        />
                                                    </>
                                                )
                                            : null
                                        }

                                    </div>

                                    <div>
                                        <BiTrash
                                        className="cursor-pointer"
                                        size={23}
                                        color="red"
                                        onClick={() => deleteProduct(index)}
                                        />
                                    </div>

                                    {/*<BsHeart  color="#DC1A22"*/}
                                    {/*         size={22}*/}
                                    {/*         className="mr-2"*/}
                                    {/*         onClick={() => setColor(!colour)}*/}
                                    {/*         style={{ color: colour ? '#cf0808' : '#e1e8e4' }}/>*/}
                                    {/*<GoHeart color="#DC1A22"*/}
                                    {/*         size={22}*/}
                                    {/*         className="mr-2"*/}
                                    {/*         onClick={() => setColor(!colour)}*/}
                                    {/*         style={{ color: colour ? '#cf0808' : '#e1e8e4' }}/>*/}


                                </div>
                            </div>
                            <div className="  mt-5 col-lg-3 col-sm-12">
                                {isCheckBox && (
                                    <div>
                                        <AiOutlineMinus
                                            className="text-muted cursor-pointer"
                                            size={22}
                                            color="black"
                                            onClick={() => changeQuantity('subtract', index)}
                                        />{' '}
                                        <input
                                            type="text"
                                            style={{width: '50px'}}
                                            name={'quantity' + index}
                                            min={0}
                                            value={cart.qty.split(';')[index]}
                                            disabled={true}
                                        />{' '}
                                        <AiOutlinePlus
                                            className="text-muted cursor-pointer"
                                            size={20}
                                            color="black"
                                            onClick={() => changeQuantity('add', index)}
                                        />{' '}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
