import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';

const DataDeletionRequest = () => {
  return (
    <>
      <SEO title="Terms and Conditions | SAARC Bazaar" />
      <Layout>
        <div className="main-content">
          {/* Start Events Area  */}
          <div className="rn-blog-area rn-section-gap">
            <div className="container">
              <div className="col-lg-12 col-md-12 col-sm-12 mt_dec--30">
                <div className="static-page">
                  <h3 className="text-center">Data Deletion Request</h3>
                  <p>At SaarcBazaar, we take your data privacy seriously. If you wish to have your personal data deleted from our records, please follow the instructions below.</p>
                  <h4>How to Request Data Deletion</h4>
                  <p>To request the deletion of your data, please send an email to <a href="mailto:info@saarcbazaar.com">info@saarcbazaar.com</a> with the subject line "Data Deletion Request." In the body of the email, please provide the following information to help us locate and process your request:</p>
                  <ol>
                    <li><strong>Full Name:</strong> Please provide your full name as it appears in our records.</li>
                    <li><strong>Email Address:</strong> The email address associated with your SaarcBazaar account.</li>
                    <li><strong>User ID:</strong> If applicable, include your SaarcBazaar user ID or account number.</li>
                    <li><strong>Additional Information:</strong> Any additional information that can help us identify your account and data.</li>
                  </ol>

                  <h4>Verification Process</h4>
                  <p>To protect your data and ensure the request is legitimate, we may need to verify your identity. This may include requesting additional information or confirmation of your request via your registered email address.</p>
                  <h4>Processing Time</h4>
                  <p>Once we receive your data deletion request and verify your identity, we will begin processing your request. Please allow up to 30 days for the complete removal of your data from our systems. During this period, your data will be securely archived and inaccessible for any purposes.</p>
                  <h4>Important Information</h4>
                  <ul>
                    <li>Deleting your data means that we will permanently remove all personal information associated with your account. This action is irreversible, and you will lose access to your SaarcBazaar account.</li>
                    <li>Please note that certain legal obligations or legitimate business interests may require us to retain some of your data even after your deletion request. We will only retain information necessary to comply with these obligations.</li>
                    <li>If you have any active orders or ongoing business transactions with SaarcBazaar, we may not be able to process your data deletion request until those transactions are completed.</li>
                    <li>You will receive a confirmation email once your data has been successfully deleted from our systems.</li>
                  </ul>
                  <h4>Contact Information</h4>
                  <p>If you have any questions or concerns regarding your data deletion request, please contact us at <a href="mailto:info@saarcbazaar.com">info@saarcbazaar.com</a>, and our support team will be happy to assist you.</p>
                  <p>Thank you for trusting SaarcBazaar. We are committed to safeguarding your privacy and ensuring the security of your personal information.</p>
                </div>
              </div>
            </div>
          </div>
          {/* End Events Area  */}
        </div>
      </Layout>
    </>
  );
};

export default DataDeletionRequest;
