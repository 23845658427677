import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SEO from '../common/SEO';
import Layout from '../common/Layout';
import Checkm from '../components/addToCartModel/DeliveryDetails';
import { useContext } from 'react';
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineInfoCircle,
  AiOutlineMinus,
  AiOutlineMinusSquare,
  AiOutlinePlus,
  AiOutlinePlusSquare,
  BiTrash,
} from 'react-icons/all';
import * as api from '../utils/api_helper';
import { getUserId } from '../utils';
import { Alert, ButtonToolbar, Modal, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { GrFormAdd } from 'react-icons/gr';
import { FiMinus } from 'react-icons/fi';
import { MdDelete } from 'react-icons/md';
import { Table, Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { get } from '../utils/api_helper';
import ProductListing from '../components/addToCartModel/ProductListing';
const Cart = (props) => {
  const [cart, setCart] = useState({});
  const [refetch, setRefetch] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [updateProducts, setUpdateProducts] = useState(true);
  const [selectAllProducts, setSelectAllProduct] = useState(true);
  const [checkboxLoading, setCheckboxLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const userId = getUserId();
  const [supplierName, setSupplierName] = useState(true);
  const [checkout, setCheckout] = useState({});
  const [variation, setVariation] = useState({})
  const [isVariation, setIsVariation] = useState(false)
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedQuantity, setSelectedQuantity] = useState(0);

  let keys = [
    'product_id',
    'product_title',
    'qty',
    'price',
    'supplier_id',
    'variation_id',
    'state',
    'currency',
    'image',
    'country',
    'supplier_name',
    'weight_per_item',
    'package_weight',
    'shipping_cost_usd'
  ];

  const emptyCart = () => {
    keys.map((item) => (cart[item] = null));
    api
      .put('/cart/' + cart.id, cart)
      .then((response) => console.log('cart cleared successfully'))
      .catch((error) => console.error('Error: ', error));
    updateCart();
  };

  const deleteProduct = (index) => {
    Object.keys(cart).map((item) => {
      if (
        item !== 'id' &&
        item !== 'createdAt' &&
        item !== 'deletedAt' &&
        item !== 'updatedAt' &&
        item !== 'UserId' &&
        item !== 'state'
      ) {
        if (cart && cart[item]) {
          let temp = cart[item].split(';');
          temp.splice(index, 1);
          cart[item] = temp.join(';');
        }
      }
    });
    updateCart();
  };

  const selectProduct = (index) => {
    let tempStatus = cart.status.split(';');

    if (tempStatus[index] === 'false') {
      tempStatus[index] = 'true';
    } else {
      tempStatus[index] = 'false';
    }

    cart.status = tempStatus.join(';');
    setUpdateProducts(!updateProducts);
  };

  const selectAll = () => {
    setCheckboxLoading(true);
    if (cart && cart.status && selectAllProducts) {
      let statuses = cart.status.split(';');
      for (let i = 0; i < statuses.length - 1; i++) {
        statuses[i] = 'true';
      }
      cart.status = statuses.join(';');
      setSelectAllProduct(!selectAllProducts);
    } else if (cart && cart.status && !selectAllProducts) {
      let statuses = cart.status.split(';');
      for (let i = 0; i < statuses.length - 1; i++) {
        statuses[i] = 'false';
      }
      cart.status = statuses.join(';');
      setSelectAllProduct(!selectAllProducts);
    }
    setCheckboxLoading(false);
    setUpdateProducts(!updateProducts);
  };

  const handleQuantity = (e, index) => {
    if (e.target.value === '0') {
      Object.keys(cart).map((item) => {
        if (
          item !== 'id' &&
          item !== 'createdAt' &&
          item !== 'deletedAt' &&
          item !== 'updatedAt' &&
          item !== 'UserId' &&
          item !== 'state' &&
          item !== 'variation_id'
        ) {
          if (cart[item]) {
            let temp = cart[item].split(';');
            temp.splice(index, 1);
            cart[item] = temp.join(';');
          }
        }
      });
      updateCart();
    } else {
      if (cart && cart.qty) {
        let qty = cart.qty.split(';');
        qty[index] = quantity;
        cart.qty = qty.join(';');
        updateCart();
      }
    }
  };

  const changeQuantity = async (change, index) => {
    if (change === 'add') {
      if (cart && cart.qty && cart.price) {
        let qty = cart.qty.split(';'); //save quantities in the form of array
        let price = cart.price.split(';');
        const unitPrice = parseFloat(price[index])/qty[index];
        qty[index] = parseInt(qty[index]) + 1; //increment the specific quantity as of index, also convert it into integer before adding
        price[index] = unitPrice*qty[index];
        cart.qty = qty.join(';'); //Change quantity back to string
        cart.price = price.join(';');
        await updateCart(); //update the cart
      }
    } else if (change === 'subtract') {
      if (cart && cart.qty) {
        let qty = cart.qty.split(';'); //save quantities in the form of array
        let price = cart.price.split(';');
        if (qty[index] === '1') {
          deleteProduct(index);
        } else {
          const unitPrice = parseFloat(price[index])/qty[index];
          qty[index] = parseInt(qty[index]) - 1; //decrement the specific quantity as of index, also convert it into integer before adding
          price[index] = unitPrice*qty[index];
          cart.qty = qty.join(';'); //Change quantity back to string
          cart.price = price.join(';');
          await updateCart(); //update the cart
        }
      }
    }
  };

  const tooltip = <Tooltip id="tooltip">Cash on Delivery</Tooltip>;

  const updateCart = async () => {
    api
      .put('/cart/' + cart.id, cart)
      .then((response) => {
        setRefetch(!refetch);
        return true;
      })
      .catch((error) => {
        console.error('Cart can not be updated', error);
        setRefetch(!refetch);
        return false;
      });
  };

  useEffect(async () => {
    const updatePrice = () => {
      setLoader(true);
      if (cart && cart.price && cart.qty) {
        cart.multiplePrice = '';
        cart.status.split(';').map((item, index) => {
          if (item === 'true') {
            cart.multiplePrice = cart.multiplePrice.concat(
              parseInt(cart.qty.split(';')[index]) * parseInt(cart.price.split(';')[index]),
              ';'
            );
          }
        });
      }
      setTimeout(() => setLoader(false), 1000);
    };
    await updatePrice();
  }, [updateProducts, selectAllProducts]);

  useEffect(() => {
    if (userId) {
      setisLoading(true);
      api
        .get('/cart/user/' + userId)
        .then((response) => {
          console.log('response', response);
          const cart = response[0];
          cart.status = '';
          cart.multiplePrice = '';

          // Add empty false for selected products
          const products = cart.product_title.split(';');
          for (let product of products) {
            cart.status = cart.status.concat(false, ';');
          }

          // Set values
          setCart(cart);
          setVariation(props.location.state.data)
          setCheckout(response);
          setisLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching cart', error);
          setisLoading(false);
        });
    }
  }, [refetch]);

  useEffect(() => {
    setTimeout(() => setErrorMessage(''), 5000)
  }, [errorMessage])

  useEffect(() => {
    getCheckoutQuantity();
  }, [updateProducts])

  // Get quantity of selected products to display on checkout button
  const getCheckoutQuantity = () => {
    if (cart) {
      const selectedProductIndexes = [];
      let statuses = [];

      // Get all statuses from cart
      if (cart.status && cart.status != '' && cart.status.includes(';')) {
        statuses = cart.status.split(';');
        if (statuses && statuses.length > 0) {
          for (let statusIndex in statuses) {
            if (statuses[statusIndex] == 'true' && statuses[statusIndex] != '') {
              selectedProductIndexes.push(parseInt(statusIndex));
            }
          }
        }

        if (selectedProductIndexes.length > 0 && cart.qty != '') {
          const quantities = cart.qty.split(';');
          let totalQuantity = 0;
          for (let index of selectedProductIndexes) {
            totalQuantity += parseInt(quantities[index]);
          }

          setSelectedQuantity(totalQuantity);
        } else {
          setSelectedQuantity(0);
        }
      }
    }
  }

  return (
    <>
      <SEO title="Cart" />
      {/*Start - Modal to confirm reporting product*/}

      <Layout>
        <div className="container-fluid p-right ">
          {/* Start Contact Area  */}
          <div className="mt--30 mb-5 ">
            <div className="row">
              <div className="col-lg-8 col-sm-12">
                <div className="cart">
                  {cart && (cart.product_title === null || cart.product_title === '') ? (
                    <Alert className="text-center mt-3" variant="danger">
                      Nothing in cart
                    </Alert>
                  ) : (
                    <div className="text-center">
                      {isLoading ? (
                        <Spinner animation={'border'} />
                      ) : (
                        <>
                          <div>
                            {errorMessage && (
                              <Alert className="text-center mt-3" variant="danger">
                                {errorMessage}
                              </Alert>
                            )}
                            <div className=" mb-4 selectall p-3 d-flex justify-content-between">
                              <div>
                                {/* Checkbox for select all */}
                                <input type="checkbox" id="check" style={{ width: '10px' }} />
                                <label
                                  className="text-muted"
                                  htmlFor={'check'}
                                  style={{ fontSize: '14px' }}
                                  onClick={() => selectAll()}
                                >
                                  SELECT ALL
                                </label>
                              </div>

                              {/* Clear cart */}
                              <div>
                                <BiTrash
                                  className="cursor-pointer"
                                  size={21}
                                  color="red"
                                  onClick={() => emptyCart()}
                                />
                                <label
                                  className="text-muted cursor-pointer"
                                  style={{ fontSize: '14px' }}
                                  onClick={() => emptyCart()}
                                >
                                  DELETE ALL
                                </label>
                              </div>
                            </div>

                            {cart?.product_title && cart?.product_title.split(';').map((item, index) => {
                                return (
                                  <ProductListing
                                    item={item}
                                    index={index}
                                    selectProduct={selectProduct}
                                    deleteProduct={deleteProduct}
                                    changeQuantity={changeQuantity}
                                    updateCart={updateCart}
                                    cart={cart}
                                    isCheckBox={true}
                                    variation={variation}
                                    isVariation={isVariation}
                                  />
                                );
                              })}
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>

              {/* Order Summary */}
              <div className=" col-lg-4 col-sm-12 ">
                <div className="selectall p-4 pb-2">
                  <div className="row">
                    <h4 className="ml-4 " style={{ 'fontSize': '17px' }}>
                      Order Summary
                    </h4>
                  </div>
                  <div className="row justify-content-between">
                    <div className="col-6">
                      <p style={{ marginBottom: 15 }}>Sub-total </p>
                    </div>
                    <div className="col-6">
                      {loader ? (
                        <Spinner animation="border" />
                      ) : (
                        <>
                          {cart && cart.multiplePrice ? (
                            <p>
                              {cart && cart.currency ? cart.currency.split(';')[0] + '. ' : null}
                              {cart.multiplePrice
                                .split(';')
                                .map(Number)
                                .reduce((a, b) => a + b)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </p>
                          ) : 
                            <>
                              {cart?.currency?.split(';')[0]}. 0.00
                            </>
                          }
                        </>
                      )}
                    </div>
                  </div>
                  <div className="row justify-content-between">
                    <div className=" d-flex align-items-center col-6">
                      <p className="deliveryValue ">Delivery</p>
                        <ButtonToolbar>
                          <OverlayTrigger placement="right" overlay={tooltip}>
                            <AiOutlineInfoCircle size={16} />
                          </OverlayTrigger>
                        </ButtonToolbar>
                    </div>
                    <div className=" d-flex align-items-center col-6 ">
                      {/*<p className="m-0">*/}
                      Cash On Delivery
                      {/*</p>*/}
                    </div>
                  </div>

                  <div>
                    {cart.product_title !== '' && cart.status?.split(';').find((state) => state == 'true') ? (
                      <Link to={{ pathname: '/checkout', state: { cart } }} className="btn checkoutButton">CHECKOUT
                        {cart && cart.product_id ? `(${selectedQuantity})` : null}
                      </Link>
                    ) : (
                      <button className="btn checkoutButton" onClick={() => setErrorMessage('Please Select Products to Checkout')}>CHECKOUT ({selectedQuantity})</button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Cart;
