import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { Link, useLocation, Redirect } from 'react-router-dom';
import { get, put } from '../../utils/api_helper';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Accordion, Button } from 'react-bootstrap';
import { Emoji } from 'react-emoji';
import { Alert, Card, Col, Modal, OverlayTrigger, Popover, Row, Table, Form, Spinner } from 'react-bootstrap';
import { RiErrorWarningFill } from 'react-icons/ri';
import DisplayProducts from './productsDisplay';
import CompanyInformation from '../../components/blog/sidebar/CompanyInfo';
import { FiSend } from 'react-icons/fi';
import { FaShareAltSquare } from 'react-icons/fa';
import { COMPANY_STATUS, getParentbyCategory, getUserId, isLoggedIn, PRODUCT_STATUS, } from '../../utils';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoLogoWhatsapp } from 'react-icons/io';
import QueryForm from '../queryFrom/QueryForm';
import SectionTitle from '../sectionTitle/SectionTitle';
import moment from 'moment';
import ReactPlayer from 'react-player';
import RecommendedProducts from '../../components/blog/itemProp/RecommendedProducts';
import FAQs from '../accordion/FAQs';
import { GrFacebook, GrTwitter } from 'react-icons/gr';
import { BsHeartFill, BsHeart } from 'react-icons/bs';
import FsLightbox from 'fslightbox-react';
import * as api from '../../utils/api_helper';
import NewMessage from '../NewMessage';
import axios from 'axios';
import ProductQuickLinks from '../../components/blog/sidebar/ProductQuickLinks';
import SEO from '../../common/SEO';
import CartModel from '../../components/addToCartModel/model';
import StarRating from '../../components/StarRating';
import RecommendedProductsTwo from '../../components/blog/itemProp/RecommendedProductsTwo';
import Star from "../../components/Star";
import { CardBody, CardImg, CardSubtitle, CardText, CardTitle } from "reactstrap";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import QueryModal from './QueryModal';
import PdfReader from '../destinations/PdfReader'
import SendNewMessage from '../SendNewMessage';

const MyCustomLightbox = (props) => {
  const customSources = [];
  for (let i = 0; i < props.path.length; i++) {
    if (props.path[i].path != '') {
      customSources.push(
        process.env.REACT_APP_SAARC_IMAGE_API +
        '/' +
        props.path[i].path.substring(12).replace(/\\/g, '/').replace(';', '')
      );
    }
  }
  return (
    <FsLightbox toggler={props.toggler} sources={customSources}/>
  );
};

const ProductDetailsContent = (props) => {
  const [showCartModel, setShowCartModel] = useState(false);
  const [convertedPrice, setConvertedPrice] = useState(0);
  const [convertedDiscountPrice, setConverteddiscountPrice] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const [wishlist, setWishlist] = useState();
  const [selectedVariation1, setSelectedVariation1] = useState('');
  const [selectedVariation2, setSelectedVariation2] = useState('');
  const [currency, setCurrency] = useState(localStorage.getItem('cur'));
  const [showProducts, setShowProducts] = useState(false);
  const [addedToWishlist, setAddedToWishlist] = useState(false);
  const [showReportModal, setShowReportModal] = React.useState(false);
  const { companyId, productId, username } = props;
  const [supplierData, setSupplierData] = useState([]);
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [showMoreItems, setShowMoreItems] = useState(false);
  const [des, setDesc] = useState('');
  const [similarProducts, setSimilarProducts] = useState([]);
  const [companyOtherProduct, setCompanyOtherProducts] = useState([]);
  const [variationFilteredData, setVariationFilteredData] = useState({});
  const [show, setShow] = useState(false);
  const [cart, setCart] = useState(props.cart);
  const [newMessage, setNewMessage] = useState(false);
  const [discountedPrices, setDiscountedPrices] = useState(0);
  const [reviewsState, setReviewsState] = useState({ product_id: productId, user_name: username });
  const [defaultVideo, setDefaultVideo] = useState('https://www.youtube.com/watch?v=oGL0ryrchOk&t=11s');
  const [formData, setFormData] = useState({ product_url: '', product_name: '', report_reason: '' });
  const [loading, setLoading] = useState(false);
  const iconSize = 20;
  const loggedIn = isLoggedIn();
  const [toggler, setToggler] = useState(false);
  const [path, setPath] = useState('');
  const [updateCart, setUpdateCart] = useState(0);
  const userId = getUserId();
  const [productReview, setProductReview] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [chamberData, setChamberData] = useState([]);
  const [selectedStar, setSelectedStar] = useState(0);
  let URLparameters = useQuery();
  const [selectedTab, setSelectedTab] = useState(URLparameters.get('t'));
  const [showQueryModal, setShowQueryModal] = useState(false);
  const [brochurePath, setBrochurePath] = useState('');
  const [productPageAd1, setProductPageAd1] = useState('');
  const [refetchReviews, setRefetchReviews] = useState(false);
  const [instantBuy, setInstantBuy] = useState(false);
  const [instantBuyProduct, setInstantBuyProduct] = useState({});
  const [currencyConversions, setCurrencyConversions] = useState([]);
  const [addToCartDisabled, setAddToCartDisabled] = useState(false);

  // Set add to cart disabled or not
  useEffect(() => {
    if (cart?.product_id.split(';')?.includes(props.data.id)) {
      setAddToCartDisabled(true);
    } else {
      if (variationFilteredData && Object.keys(variationFilteredData).length === 0) {
        if (props?.data?.stock_quantity === 0) {
          setAddToCartDisabled(true);
        } else {
          setAddToCartDisabled(false); // Set to false if stock is available
        }
      } else {
        if (variationFilteredData?.qty === 0) {
          setAddToCartDisabled(true);
        } else {
          setAddToCartDisabled(false); // Set to false if quantity is available
        }
      }
    }

  }, [props?.data?.stock_quantity, variationFilteredData])

  const renderSliderItems = () => {
    const { data, attachments } = props;
    const variationFilteredData = props.variationFilteredData; // Assuming this holds variation data

    const sortedAttachments = attachments
      .sort((a, b) =>
        createURL(a.path).split('/').pop() > createURL(b.path).split('/').pop() ? 1 : -1
      );

    return sortedAttachments.map((item, index) => {
      if (!item || item.path === '') return null;

      let createUrl = item.path.substring(12).replace(/\\/g, '/').slice(0, -1);

      if (data.video_URL && index === 0) {
        // Render ReactPlayer for the first item if it's a video
        return (
          <div key={index}>
            <ReactPlayer
              controls={true}
              width="100%"
              height="200px"
              url={data.video_URL}
            />
          </div>
        );
      } else {
        // Render images for other items
        return (
          <div key={index}>
            {variationFilteredData && Object.keys(variationFilteredData).length > 0 ? (
              <img
                onClick={() => { setToggler(!toggler) }}
                className="slide-image-video" // Class for video
                src={process.env.REACT_APP_SAARC_IMAGE_API + '/' + variationFilteredData?.attachment?.replace('attachments', '')?.replace(';', '')}
                alt={props.data.title}
              />
            ) : (
              <img
                onClick={() => { setToggler(!toggler); setPath(process.env.REACT_APP_SAARC_IMAGE_API + '/' + createUrl) }}
                className="slide-image" // Class for image
                src={process.env.REACT_APP_SAARC_IMAGE_API + '/' + createUrl}
                alt={props.data.title}
              />
            )}
          </div>
        );
      }
    });
  };

  const handleBuyNow = async () => {
    const product = {};
    product.qty = '1';
    product.country = props.data.Company.company_country;
    product.product_title = props.data.title;

    //this code is written to cater variations
    if (discountedPrices && discountedPrices.length > 0 && discountedPrices[0]) {
      product.price = discountedPrices[0].toString();
    }
    else if (variationFilteredData) {
      product.price = variationFilteredData.price;
    } else {
      product.price = convertedPrice;
    }

    product.product_id = props.data.id;
    product.supplier_id = props.data.Company.id;
    product.supplier_name = props.data.Company.name;
    product.currency = currency;

    if (variationFilteredData && Object.keys(variationFilteredData).length > 0 && variationFilteredData?.attachment) {
      product.image = variationFilteredData.attachment?.replace('attachments', '')?.replace(';', '');
    }
    else if (props.attachments && props.attachments.length > 0 && props.attachments[0] && props.attachments[0].path) {
      product.image = props.attachments[0].path.replaceAll(';', '');
    }

    // Get variation id
    let variation_id = '0';
    if (variationFilteredData && Object.keys(variationFilteredData).length > 0 && variationFilteredData?.id) {
      variation_id = variationFilteredData.id;
    }

    // check if we have variations in cart
    product.variation_id = variation_id;
    product.status = true;
    product.followedCheckoutProcess = false;
    product.multiplePrice = product.price;


    let modifiedProduct = {}
    for (let key in product) {
      modifiedProduct[key] = product[key] + ';';
    }

    setInstantBuy(true);
    setInstantBuyProduct(modifiedProduct);
  }

  let dsc = props.data.description;
  let sum = 0;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  useEffect(() => {
    if (URLparameters.get('display') === 'query') {
      setShowQueryModal(true)
    }
  }, [URLparameters]);

  const handleChange = (event) => {
    setReviewsState({
      ...reviewsState,
      [event.target.name]: event.target.value,
    });

  };

  const handleCancel = () => {
    setReviewsState((prevState) => ({ ...prevState, comment: "" }));
  };

  // Handle submit for reviews
  const handleSubmit = async (event) => {
    event.preventDefault();
    let obj = { ...reviewsState, rating: selectedStar, user_name: username, product_id: productId, is_fulfilled: false }

    await api
      .put('reviews/order/' + reviewsState[0]?.order_id, obj)
      .then((resp) => {
        // TODO: Show success later
        setRefetchReviews(!refetchReviews);
      })
      .catch((e) => {
        console.error(`Failed to save reviews`, e);
      });
  };

  productReview.forEach((review) => {
    const rating = parseInt(review.rating);
    if (!isNaN(rating)) {
      sum += rating;
    }
  });

  const oneStarCount = productReview.reduce((count, review) => {
    if (review.rating === "1") {
      count++;
    }
    return count;
  }, 0);

  const twoStarCount = productReview.reduce((count, review) => {
    if (review.rating === "2") {
      count++;
    }
    return count;
  }, 0);

  const threeStarCount = productReview.reduce((count, review) => {
    if (review.rating === "3") {
      count++;
    }
    return count;
  }, 0);

  const fourStarCount = productReview.reduce((count, review) => {
    if (review.rating === "4") {
      count++;
    }
    return count;
  }, 0);

  const fiveStarCount = productReview.reduce((count, review) => {
    if (review.rating === "5") {
      count++;
    }
    return count;
  }, 0);

  const handleTabClick = (index) => {
    setActiveTab(index === activeTab ? null : index);
  };

  useEffect(() => {
    if (props?.data?.brochure) {
      api
        .get('uploads/' + props.data.brochure)
        .then((res) => {
          if (res.path !== '') {
            setBrochurePath(process.env.REACT_APP_SAARC_IMAGE_API + '/' + res.path.substring(11).replace(/\\/g, '/'));
          }
        })
        .catch((err) => console.error('error', err));
    }

    // Set default selected variations
    if (props && props.variation1) {
      if (props.variation1.length > 0) {
        setSelectedVariation1(props.variation1[0])
      }
    }

    if (props && props.variation2) {
      if (props.variation2.length > 0) {
        setSelectedVariation2(props.variation2[0])
      }
    }
  }, [])

  useEffect(() => {
    // TODO: get review for specific product only
    api
      .get('reviews')
      .then(resp => {
        const allFilteredReviews = resp.filter(review => review.product_id === productId);
        setProductReview(allFilteredReviews)
        const filteredReviews = resp.filter(review =>
          review.product_id === productId && review.user_id === userId
        );
        setReviewsState(filteredReviews);
      })
      .catch((e) => {
        console.error(`Failed`, e);
      });
  }, [refetchReviews])

  useEffect(() => {
    const getCart = () => {
      if (userId) {
        api
          .get('/cart/user/' + userId)
          .then((response) => {
            setCart(response[0]);
          })
          .catch((error) => console.error('Error fetching cart', error));
      }
    };
    getCart();
  }, [updateCart]);

  const handleCloseReport = () => setShowReportModal(false);
  const handleShowReport = () => setShowReportModal(true);

  useEffect(() => {
    // Filter selected variations from variation arrays, choose which one is selected
    let filteredData = {};
    if (selectedVariation1 && selectedVariation2) {
      filteredData = props.variationData.find((data) => data.variation1?.trim() == selectedVariation1?.trim() && data.variation2?.trim() == selectedVariation2?.trim());
    } else if (selectedVariation1) {
      filteredData = props.variationData.find((data) => data.variation1?.trim() == selectedVariation1?.trim())
    } else if (selectedVariation2) {
      filteredData = props.variationData.find((data) => data.variation2?.trim() == selectedVariation2?.trim())
    }

    if (filteredData && Object.keys(filteredData).length > 0) {
      // Set prices after converting
      if (props.currencyPerUnit > 0) {
        filteredData.price = (props.currencyPerUnit * filteredData.price).toFixed(2);
        filteredData.discounted_price = (props.currencyPerUnit * filteredData.discounted_price).toFixed(2);
      } else {
        // if (filteredData.price && filteredData.discounted_price) {
        filteredData.price = parseFloat(filteredData.price)?.toFixed(2);
        filteredData.discounted_price = parseFloat(filteredData.discounted_price)?.toFixed(2);
        // }
      }
    }

    if (filteredData) {
      setVariationFilteredData(filteredData)
    }
  }, [selectedVariation1, selectedVariation2]);

  useEffect(async () => {
    if (props.data && props.data.price && props.data.currency) {
      if (props.currencyPerUnit > 0) {
        const unit = props.currencyPerUnit;
        const usdEquivalentPrice = (unit * parseFloat(props.data.price)).toFixed(2);
        setConvertedPrice(usdEquivalentPrice);

        // Convert discounted price to selected currency
        const usdEquivalentDiscountedPrice = (unit * parseFloat(props.data.discounted_price)).toFixed(2);
        setConverteddiscountPrice(usdEquivalentDiscountedPrice);
      }
    }
  }, [props.data.price, props.currencyPerUnit]);

  // Get product page ads
  const getAds = async () => {
    await get('/advertisement')
      .then((response) => {
        // const homepageAd1 = response.filter((ads) => ads.area == 'homepage_ad_1');
        const productPageAdsFiltered = response.filter((ads) => ads.area.includes('product_page_ad'))

        if (productPageAdsFiltered && productPageAdsFiltered.length > 0) {
          for (let ads of productPageAdsFiltered) {
            ads.image_path = process.env.REACT_APP_SAARC_IMAGE_API + '/' + ads.image_path.substring(12).replace(/\\/g, '/')
          }
          setProductPageAd1(productPageAdsFiltered[0]);
        }
      })
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    getAds();
  }, [])

  useEffect(() => {
    setLoading(true);
    async function getSimilarProducts() {
      if (!productId) return;
      await get('/listings/similar/' + productId)
        .then((resp) => {
          resp.splice(resp.findIndex((i) => i.id === productId), 1);
          setSimilarProducts(resp);
          setLoading(false);
        })
        .catch((err) => {
          console.error('error getting products', err);
          setLoading(false);
        });
    }

    async function getCompanyOtherProducts() {
      await get('/listings/company/' + props?.data?.Company?.id + '?getAll=true')
        .then((resp) => {
          // resp &&
          //   resp.map(async (item) => {
          //     // convert currency for all companys products
          //     if (item?.currency != '' && item?.price != '') {
          //       // await convertCurrency(item);
          //     }
          //   });
          setCompanyOtherProducts(resp);
        })
        .catch((err) => {
          console.error('error fetching listngs by company id', err);
        });
    }
    getSimilarProducts();
    getCompanyOtherProducts();
  }, []);

  const createURL = (path) => {
    return path.substring(12).replace(/\\/g, '/').slice(0, -1);
  };

  // const scrollToIdd = (e, name) => {
  //   e.preventDefault();
  //   if (name === 'product') {
  //     if (document.getElementById('product')) {
  //       document.getElementById('product').scrollIntoView();
  //     }
  //   }
  // }

  function createArray(length, rating) {
    return Array.from({ length }, (_, index) => {
      return <Star key={index} selected={index < rating} onSelect={() => setSelectedStar(index + 1)} />;
    });
  }

  const createArrays = (length) => [...Array(length)];

  useEffect(() => {
    if (props?.attachments) { // Checking for props.attachments
      if (props?.data?.video_URL && props?.data?.video_URL !== '') {
        console.log(props.attachments); // Logging the current state
    
        // Adding an empty object to props.attachments array
        props.attachments.unshift({ path: '' });
      }
    }    
  }, [])

  const settings = {
    // slick custom paging for image thumbs
    customPaging: function (i) {
      if (props.data.video_URL && props.data.video_URL != '') {
        // If this is true then 0th index will be the video
        if (i == 0) {
          return (
            <a className="slick-thumb">
              <img src='/images/videothumb.png' />
            </a>
          )
        }
      }
      if (props && props.attachments && props.attachments[i] && props.attachments[i].path) {
        const createUrl = createURL(props.attachments[i].path);
        return (
          <a className="slick-thumb">
            <img
              src={`${process.env.REACT_APP_SAARC_IMAGE_API}/${createUrl}`}
              alt={props.data.title}
            />
          </a>
        );
      } else {
        return <></>
      }
    },
    arrows: false,
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    lazyLoad: true,
  };

  //Similar products slider settings
  const similar_settings = {
    dots: false,
    infinite: similarProducts.length > 4,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4600,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const onchange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // function to move screen to relavent id in horizontal buttons
  const scrollToId = (e, name) => {
    e.preventDefault();
    if (name === 'faqs') {
      if (document.getElementById('faqs')) {
        document.getElementById('faqs').scrollIntoView();
      }
    } else if (name === 'query') {
      handleTabClick(0);
      if (document.getElementById('query')) {
        document.getElementById('query').scrollIntoView();
      }
    } else if (name === 'products') {
      if (document.getElementById('products')) document.getElementById('products').scrollIntoView();
    }
    else if (name === 'reviews&rating') {
      if (document.getElementById('reviews&rating')) document.getElementById('reviews&rating').scrollIntoView();
    }
    else if (name === 'youmayalsolike') {
      if (document.getElementById('youmayalsolike')) document.getElementById('youmayalsolike').scrollIntoView();
    }
  };

  useEffect(() => {
    async function getWishlist() {
      await get('/wishlist/user/' + userId)
        //this useEffect gets all the wishlist and then sees if id matches and if it does setAddedToWishlist is trye
        .then((resp) => {
          if (resp && resp[0]) {
            setWishlist(resp[0]);
            if (resp[0].productIds?.split(';').find((id) => id === productId)) {
              setAddedToWishlist(true);
            }
          }
        })
        .catch((err) => console.error('Error getting wishlist', err));
    }

    if (userId) {
      getWishlist();
    }
  }, []);

  //add to wishlist on product page
  const addToWishlist = async (productId) => {
    if (!loggedIn) {
      window.location = process.env.REACT_APP_SAARC_DEFAULT_URL + '/users/login';
    } else {
      if (wishlist && !props.preview)
        await put('/wishlist/product/' + wishlist.id, { productIds: productId })
          .then((resp) => {
            setAddedToWishlist(true);
          })
          .catch((err) => {
            console.error('Could not add to wishlist', err);
          });
    }
  };

  const removeFromWishlist = async () => {
    let updatedIDs = wishlist.productIds.replace(productId, '').replace(/(^;)/g, '');
    await put('/wishlist/delete/' + wishlist.id, { productIds: updatedIDs })
      .then(() => {
        setAddedToWishlist(false);
      })
      .catch((err) => console.error('Error updating wishlist', err));
  };
  useEffect(() => {
    async function getData() {
      if (props.data.description) {
        let tempDescription = stateToHTML(convertFromRaw(JSON.parse(dsc)));
        setDesc(tempDescription);
        setShowMore(tempDescription.length < 250);
      }
    }
    getData();
  }, [props.data.description]);
  const sendReport = (e) => {
    e.preventDefault();
    if (loggedIn) {
      setShowReportModal(false);
      formData.product_name = props.data.title;
      formData.product_url =
        process.env.REACT_APP_SAARC_DEFAULT_URL + '/product-details/' + props.data.id;
      api
        .post('listings/report', formData)
        .then((resp) => {
          setFormData('');
        })
        .catch((e) => {
          console.error(`Failed to send email`, e);
        });
    } else {
      setShowReportModal(false);
      window.location = process.env.REACT_APP_SAARC_DEFAULT_URL + '/users/login';
    }
  };
  const [cartLoading, setCartLoading] = useState(false);

  return (
    <>
      <SEO title={props.data.title} description={des} />
      {instantBuy && (
        <Redirect
          to={{
            pathname: '/checkout', state: { cart: instantBuyProduct },
          }}
        />
      )}
      <QueryModal show={showQueryModal} setShow={setShowQueryModal} company={props.data.Company} productData={props.data} preview={null} />
      <Modal show={showReportModal} onHide={handleCloseReport}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            If you found this profile or product to be against our Terms and Conditions and think it
            should be removed from SAARC Bazaar then click the Report button.
            <br />
          </p>
          <h5> Please include the reason for reporting</h5>
          <form>
            <input type="text-area" name="report_reason" onChange={onchange} />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={(e) => sendReport(e)} className="btn-default btn-primary btn-small">
            <FiSend /> Send Report
          </button>
          <button className="btn-default btn-danger btn-small " onClick={handleCloseReport}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      {/*Ends - Modal to confirm reporting product*/}

      {/* Lightbox to open images in full screen */}
      <MyCustomLightbox toggler={toggler} path={props.attachments} />

      {newMessage && (
        <SendNewMessage
          companyId={props.data.Company.id}
          companyName={props.data.Company.name}
          newMessage={newMessage}
          setNewMessage={setNewMessage}
        />
      )}

      <div className="data-element" style={{ padding: '0px' }}>
        <div className="rwt-portfolio-details ">
          <div className="container">
            <div className="inner">
              <div className="details-list">
                <div className="row mt--40 ">
                  <div className="col-lg-9 col-sm-12">
                    <div className="row">
                      <div className="col-lg-12 col-sm-12">
                        {(props.data.status !== PRODUCT_STATUS.APPROVED || !props.data.state) &&
                          props.data.status !== PRODUCT_STATUS.PUPDATE && (
                            <Alert variant="warning" className="text-center font-weight-bold" >
                              {`This Product is ${props.data.state ? 'Enabled' : 'Disabled'} & in ${props.data.status
                                } Status`}
                            </Alert>
                          )}

                        {props?.preview && (
                          <Alert variant="warning" className="text-center font-weight-bold">{`Product Preview`}</Alert>
                        )}

                        <div className="d-flex"><h4 className="mb-0">{props.data.title}</h4></div>
                        <p className="product-tagline mb-3">{props.data.tagline}</p>
                        <p className="product-tagline mb-2 color-midgray">
                          {props && props.data ? getParentbyCategory(props.data.category) : ''} {'> '} {props.data.category}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 mt_md--30 mt_sm--30 col-sm-12 ">
                        <div className="content-left">
                          {/* Attachments/Gallery */}
                          <Slider {...settings}>
                            {props?.data?.video_URL && (
                              <div>
                                <ReactPlayer
                                  config={{
                                    facebook: {
                                      attributes: {
                                        'data-width': '400px',
                                        'data-height': '200px',
                                      },
                                    },
                                  }}
                                  controls={true}
                                  width="100%"
                                  height="200px"
                                  url={props.data.video_URL}
                                  className="something"
                                />
                              </div>
                            )}

                            {props && props.attachments && Object.keys(props.attachments).length > 0 &&
                              props.attachments
                                .sort((a, b) => createURL(a.path).split('/').pop() > createURL(b.path).split('/').pop() ? 1 : -1)
                                .map((item, index) => {
                                  if (!item || item.path == '') return;
                                  let createUrl = item.path.substring(12).replace(/\\/g, '/').slice(0, -1);
                                  return (
                                    <div key={index}>
                                      {variationFilteredData && Object.keys(variationFilteredData).length > 0 ?
                                        <img
                                          onClick={() => { setToggler(!toggler) }}
                                          className="slide-image "
                                          src={process.env.REACT_APP_SAARC_IMAGE_API + '/' + variationFilteredData?.attachment?.replace('attachments', '')?.replace(';', '')}
                                          alt={props.data.title}
                                        />
                                        :
                                        <img 
                                          onClick={() => { setToggler(!toggler); setPath(process.env.REACT_APP_SAARC_IMAGE_API + '/' + createUrl) }}
                                          className="slide-image"
                                          src={process.env.REACT_APP_SAARC_IMAGE_API + '/' + createUrl}
                                          alt={props.data.title}
                                        />
                                      }
                                    </div>
                                  )
                                })}
                          </Slider>

                          {/* Video or Ad */}
                          {false ? (
                            <></>
                          ) :
                            <a href={productPageAd1?.advert_url} target='_blank' className='border-1'>
                              <div className='product-page-ad-container'>
                                <img
                                  src={productPageAd1?.image_path}
                                  alt="Ad"
                                  style={{ width: '100%', height: '100%' }}
                                />
                                <div className="icon-container">
                                  <span className="info-icon">Ad</span>
                                </div>
                              </div>
                            </a>
                          }

                          {/* Show tags */}
                          {/* Tags hidden due to clients request */}
                          {/* {props && props.data && props.data.tags ? (
                            <>
                              <h5>
                                <GiHamburgerMenu color="#666666" /> {'   '}
                                Tags
                              </h5>
                              <ul className="tagcloud">
                                {props.data.tags
                                  .split(';')
                                  .map((item) =>
                                    item !== '' && item !== null && item.length > 1 ? (
                                      <Link to={'/search?option=products&s=' + item}>{item}</Link>
                                    ) : null
                                  )}
                              </ul>
                            </>
                          ) : null} */}
                        </div>
                      </div>
                      <div className="col mt_md--50 mt_sm--50">
                        <div className="content-right">
                          <div id="portal" style={{ width: '100%' }}></div>
                          <div className="body-font ">
                            {props && props.data.description ? (
                              <>
                                <h5>
                                  <GiHamburgerMenu color="#666666" /> {'   '}
                                  Product Description
                                </h5>
                                {showMore ? (
                                  <>
                                    <div
                                      className="company_desc mb-5"
                                      dangerouslySetInnerHTML={{ __html: des }}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className="company_desc mb-5"
                                      dangerouslySetInnerHTML={{
                                        __html: des.substring(0, 250) + '...',
                                      }}
                                    />
                                  </>
                                )}
                                <a
                                  className="btn-showmore  float-right"
                                  onClick={() => setShowMore(!showMore)}
                                >
                                  {des.length > 250 ? (
                                    <>{showMore ? 'Show Less' : 'Show more'}</>
                                  ) : null}
                                </a>
                              </>
                            ) : null}
                            <div>
                              {/* Variation 1 buttons */}
                              <div>
                                {props?.variation1?.length > 0 && props?.variation1[0] && (<h5 className=" mr-2 d-inline">Variant</h5>)}
                                {props.variation1.map((variation1, index) => (
                                  <>
                                    {variation1 && variation1 != '' && (
                                      <button key={index}
                                        className={`btn border-1 m-2 text-size-14  square border border-secondary ${selectedVariation1 === variation1 ? 'btn-primary' : 'btn-gray'}`}
                                        onClick={() => { setSelectedVariation1(variation1) }}
                                      >
                                        {variation1}
                                      </button>
                                    )}
                                  </>
                                ))}
                              </div>
                              <div>

                                {/* Variation 2 buttons */}
                                {props?.variation2?.length > 0 && props?.variation2[0] && (<h5 className=" mr-2 d-inline">Variant</h5>)}
                                {props.variation2.map((variation2, index) => (
                                  <>
                                    {variation2 != null && (
                                      <button key={index}
                                        className={`btn border-1 m-2 text-size-14 square border border-secondary ${selectedVariation2 === variation2 ? 'btn-primary' : 'btn-gray'}`}
                                        onClick={() => { setSelectedVariation2(variation2) }}
                                      >
                                        {variation2}
                                      </button>
                                    )}
                                  </>
                                ))}
                              </div>
                            </div>

                            <div className="mb-5 mt-4">
                              <h5><GiHamburgerMenu color="#666666" /> {'   '}Product Information</h5>
                              <Table className="table table-bordered">
                                <tbody>
                                  {/*  Brand Name */}
                                  {props?.data?.brand_name && (
                                    <tr>
                                      <td>Brand Name:</td>
                                      <td>{props.data.brand_name}</td>
                                    </tr>
                                  )}

                                  {/* Model */}
                                  {props?.data?.model && (
                                    <tr>
                                      <td>Model:</td>
                                      <td>{props.data.model}</td>
                                    </tr>
                                  )}

                                  {/* Warranty type */}
                                  {props?.data?.warranty_type && (
                                    <tr>
                                      {props.data.warranty_type == 'No Warranty' ?
                                        <>
                                          <td>Warranty</td>
                                          <td>{props.data.warranty_type}</td>
                                        </>
                                        :
                                        <>
                                          <td>{props.data.warranty_type}</td>
                                          <td>{props?.data?.warranty_period}</td>
                                        </>
                                      }
                                    </tr>
                                  )}

                                  {/* Place of origin */}
                                  {props?.data?.origin ? (
                                    <tr>
                                      <td>Place of Origin</td>
                                      <td>{props.data.origin}</td>
                                    </tr>
                                  ) : null}

                                  {/* Domestic Delivery lead time */}
                                  {props?.data?.domestic_delivery_time && (
                                    <tr>
                                      <td>Domestic Delivery Time</td>
                                      <td>{props.data.domestic_delivery_time}</td>
                                    </tr>
                                  )}

                                  {/* International delivery lead time */}
                                  {props?.data?.international_delivery_time && (
                                    <tr>
                                      <td>International Delivery Time</td>
                                      <td>{props.data.international_delivery_time}</td>
                                    </tr>
                                  )}

                                  {/* Major export market */}
                                  {props?.data?.major_export_market && (
                                    <tr>
                                      <td>Major Export Market</td>
                                      <td>{props.data.major_export_market}</td>
                                    </tr>
                                  )}

                                  {/* Export Mode */}
                                  {props?.data?.trade_mode !== null ? (
                                    <tr>
                                      <td>Export/Import Mode</td>
                                      <td>{props.data.trade_mode}</td>
                                    </tr>
                                  ) : null}

                                  {/* Product Certifications */}
                                  {props?.data?.certification && (
                                    <>
                                      <tr>
                                        <td>Certifications</td>
                                        <td>{props.data.certification || 'Not Provided'}</td>
                                      </tr>
                                    </>
                                  )}

                                  {/* MOQ */}
                                  {props?.data?.moq && (
                                    <tr>
                                      <td>Minimum Order Quantity</td>
                                      <td>{props.data.moq}</td>
                                    </tr>
                                  )}

                                  {/* No of items per carton */}
                                  {props?.data?.items_per_carton && (
                                    <tr>
                                      <td>Items Per Carton/Package</td>
                                      <td> {props.data.items_per_carton}</td>
                                    </tr>
                                  )}

                                  {/* Weight per carton */}
                                  {props?.data?.weight_per_carton && (
                                    <tr>
                                      <td>Weight Per Carton</td>
                                      <td>{props.data.weight_per_carton}</td>
                                    </tr>
                                  )}

                                  {/* Dimensions */}
                                  {(props?.data?.length || props?.data?.width || props?.data?.height) !== null && (
                                    <tr>
                                      <td>Dimensions</td>
                                      <td>{`Length: ${props?.data?.length || 'Not Provided'} Width: ${props?.data?.width || 'Not Provided'} x Height: ${props?.data?.height || 'Not Provided'}`}</td>
                                    </tr>
                                  )}

                                  {/* Available Stock */}
                                  {props?.data?.stock_quantity && (
                                    <tr>
                                      <td>Available Stock</td>
                                      <td>{props.data.stock_quantity}</td>
                                    </tr>
                                  )}

                                  {/* Payment term */}
                                  {props?.data?.payment_terms && (
                                    <tr>
                                      <td>Payment Terms</td>
                                      <td>{props.data.payment_terms}</td>
                                    </tr>
                                  )}

                                  {/* Non mandatory items on show more */}
                                  {showMoreItems && (
                                    <>
                                      {props?.data?.prod_capacity ? (
                                        <tr>
                                          <td>Production Capacity Per Month</td>
                                          <td>{props.data.prod_capacity}</td>
                                        </tr>
                                      ) : null}

                                      {props?.data?.trade_mode ? (
                                        <tr>
                                          <td>Shipping</td>
                                          <td>{props.data.trade_mode}</td>
                                        </tr>
                                      ) : null}
                                    </>
                                  )}
                                </tbody>
                              </Table>
                            </div>

                            {/* Show more button */}
                            <div className="text-align-end">
                              <button onClick={() => setShowMoreItems(!showMoreItems)} className="btn justify">{showMoreItems ? 'Show Less' : 'Show More'}</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={'col-lg-3 mt_md--40 mt_sm--40'}>
                    {/*<div*/}
                    {/*  className={*/}
                    {/*    isLoggedIn()*/}
                    {/*      ? 'd-none'*/}
                    {/*      : 'd-block' + 'd-none mt-5 position-absolute container register-now'*/}
                    {/*  }*/}
                    {/*>*/}
                    {/*  <RegisterNow />*/}
                    {/*</div>*/}

                    <div>
                      <div className=" data-element-sidebar-product rbt-single-widget widget_categories">
                        <div className="mt_sm--20">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="text-center ">
                                {addedToWishlist ? (
                                  <>
                                    <button className="btn" onClick={() => removeFromWishlist()}>
                                      <BsHeartFill size={13} color="#DC1A22" /> Remove
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      className="btn"
                                      onClick={() => addToWishlist(props.productId)}
                                    >
                                      <BsHeart size={13} color="#DC1A22" /> Add to Wishlist
                                    </button>
                                  </>
                                )}


                                <OverlayTrigger
                                  trigger="click"
                                  key="top"
                                  placement="top"
                                  overlay={
                                    <Popover id={`popover-positioned-top`}>
                                      <Popover.Title as="h4">{`Share to`}</Popover.Title>
                                      <Popover.Content className="p-3">
                                        <a
                                          href={`https://www.facebook.com/share.php?u=https://saarcbazaar.com/product-details/${props.data.id}`}
                                          target="_blank"
                                          className="pr-3"
                                        >
                                          <GrFacebook color="#4267B2" size={iconSize} />
                                        </a>
                                        <a
                                          href={`https://twitter.com/intent/tweet?text=${props.data.title}%20https://saarcbazaar.com/product-details/${props.data.id}`}
                                          target="_blank"
                                        >
                                          <GrTwitter color="#00acee" size={iconSize} />
                                        </a>
                                        {'     '}
                                        <a
                                          href={`https://api.whatsapp.com/send/?phone=&text=${props.data.title}%20https://saarcbazaar.com/product-details/${props.data.id}&app_absent=0`}
                                          data-action="share/whatsapp/share"
                                          target="_blank"
                                        >
                                          <IoLogoWhatsapp color="#25D366" size={iconSize} />
                                        </a>
                                      </Popover.Content>
                                    </Popover>
                                  }
                                >
                                  <button className="btn">
                                    <FaShareAltSquare size={15} color="#298DE0" /> Share
                                  </button>
                                </OverlayTrigger>
                                <button className="btn" onClick={handleShowReport}>
                                  {/*<MdReport size={15} color="orange" /> Report*/}
                                  <RiErrorWarningFill size={15} color="orange" /> Report
                                </button>
                              </div>
                              <hr />
                              {props && props.data && props.data.UserId === userId ? (
                                <a
                                  className="btn btn-chat btn-icon bg-danger"
                                  href={`/users/listings/${props?.data?.id}`}
                                >
                                  Edit this Product
                                </a>
                              ) : null}
                              {productReview.length === 0 ? '' :
                                <div className="mb-1">
                                  {isNaN(sum / productReview.length) ? '' : createArray(5, sum / productReview.length)}
                                  {` `}
                                  {isNaN(sum / productReview.length) ? '' : `${sum / productReview.length} Rating`}
                                </div>
                              }

                              {/* If we do not have filtered variation i.e. selected variation */}
                              {/* We will show product price */}
                              <h4 className="mb-3">
                                {variationFilteredData && Object.keys(variationFilteredData).length == 0 ? (
                                  <>
                                    {props?.data?.price && props?.data?.price > 0 && convertedPrice > 0 && props.data.hasPrice ?
                                      <>
                                        <span className={props?.data?.discounted_price > 0 ? 'd-none' : ''}>
                                          {currency + '.  ' + convertedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        </span>
                                        {props?.data?.discounted_price > 0 && (
                                          <>
                                            <>
                                              {currency + '. ' + convertedDiscountPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                              <br />
                                              <span className='product-price-strikethrough'>
                                                {currency + '.  ' + convertedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                              </span>
                                            </>
                                          </>
                                        )}
                                      </>
                                      : 
                                      <>
                                        {/* Ask for price */}
                                        <span onClick={() => setShowQueryModal(true)} className='cursor-pointer'>
                                          Ask for Price
                                        </span>
                                      </>
                                      }

                                    {props.data.discount_percentage > 0 && (<span className='discount-percentage'>-{props.data.discount_percentage}%</span>)}
                                    <br/>
                                    <span className='product-quantity'>Quantity: {props?.data?.stock_quantity}</span>
                                  </>
                                ) : (
                                  <>
                                    {/* If we have selected variation, we will show variation price */}
                                    {parseFloat(variationFilteredData?.discounted_price) > 0 ?
                                      <>
                                        {currency + '. ' + variationFilteredData.discounted_price}<br />
                                        <span className="text-size-14">Price</span><span style={{ textDecoration: 'line-through' }} className="text-size-14"> {currency + '. ' + variationFilteredData.price}</span>{' '}
                                        <span className="yellow-percent text-size-14"> ({variationFilteredData.discount_percentage}%)</span>
                                      </>
                                      :
                                      <>
                                        {currency + '. ' + variationFilteredData.price}<br />
                                      </>
                                    }
                                    <br/>
                                    <span className='product-quantity'>Quantity: {variationFilteredData?.qty}</span>
                                  </>
                                )}
                              </h4>

                              {props?.data?.price && props?.data?.price > 0 && convertedPrice > 0 && props.data.hasPrice
                                ?
                                <>
                                  <Button
                                    className="btn btn-chat btn-icon"
                                    onClick={() => setShowCartModel(true)}
                                    disabled={addToCartDisabled}
                                  >
                                    {cart?.product_id?.split(';')?.includes(props.data.id)
                                      ?
                                      `Already in cart (${cart.qty.split(';')[cart.product_id.split(';').indexOf(props.data.id)]})`
                                      :
                                      'Add to Cart'}
                                  </Button>

                                  <Button 
                                    disabled={addToCartDisabled} 
                                    className="btn btn-chat btn-icon" 
                                    onClick={() => handleBuyNow()}
                                  >
                                    Buy Now
                                  </Button>
                                </>
                                :
                                <>
                                  {/* <Button
                                    className="btn btn-chat btn-icon"
                                    onClick={() => setShowQueryModal(true)}
                                  >
                                    Ask For Price
                                  </Button> */}
                                </>
                              }



                              {/*<AddToCartModel />*/}
                              {showCartModel && (
                                <CartModel
                                  country={props?.data?.Company?.company_country}
                                  image={props.attachments}
                                  variationFilteredData={variationFilteredData}
                                  showCartModal={showCartModel}
                                  username={username}
                                  setShowCartModal={setShowCartModel}
                                  productItem={props.data}
                                  cartLoading={cartLoading}
                                  setCartLoading={setCartLoading}
                                  updateCat={updateCart}
                                  setUpdateCart={setUpdateCart}
                                  attachments={props.attachments}
                                  convertedPrice={variationFilteredData && Object.keys(variationFilteredData).length > 0 ? parseFloat(variationFilteredData.price) : parseFloat(convertedPrice)}
                                  discountedPrice={variationFilteredData && Object.keys(variationFilteredData).length > 0 ? parseFloat(variationFilteredData.discounted_price) : parseFloat(convertedDiscountPrice)}
                                  setShowQueryModal={setShowQueryModal}
                                />
                              )}



                              {/*<Model />*/}
                            </div>
                          </div>
                          {/* End Elements Area  */}
                        </div>
                      </div>

                      {/* Verified Member Badge */}
                      {(props?.data?.Company?.Chamber?.status === COMPANY_STATUS.APPROVED || props?.data?.Company?.category?.split(';').find((cat) => cat == 'Women Entrepreneur') || props?.data?.warranty_type?.trim() !== 'No Warranty' && props?.data?.warranty_type?.trim() !== '') && (
                        <div className="data-element-sidebar-product rbt-single-widget widget_categories">
                          <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
                            {props?.data?.Company?.Chamber?.status === COMPANY_STATUS.APPROVED && (
                              <div className="col-4">
                                <img src="/images/icons/verified.png" alt="Warranty Badge" title="SAARC Bazaar Verified Supplier" />
                              </div>
                            )}
                            {props?.data?.Company?.category?.split(';').find((cat) => cat == 'Women Entrepreneur') && (
                              <div className="col-4">
                                <img src="/images/icons/ribbon.png" alt="Warranty Badge" title="This product belongs to a women entrepreneur" />
                              </div>
                            )}
                            {props?.data?.warranty_type?.trim() !== 'No Warranty' && props?.data?.warranty_type?.trim() !== '' && (
                              <div className="col-4">
                                <img src="/images/icons/warranty.png" alt="Warranty Badge" title="This product has a warranty" />
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      <div className={loggedIn ? 'd-none' : null + ' mb-5 company-page-banner'}>
                        <Card className="register-now-card">
                          <Card.Body>
                            <Card.Text color="#fff">
                              Register now and explore opportunities all over South Asia!
                            </Card.Text>
                            {/*check code*/}
                            <div className="mt_dec--20 text-center">
                              <a href={process.env.REACT_APP_SAARC_DEFAULT_URL + '/users/login'}>
                                <Button size="lg" color="green">
                                  Sign In
                                </Button>
                              </a>
                              <a href={process.env.REACT_APP_SAARC_DEFAULT_URL + '/users/login'}>
                                <Button size="lg" color="green">
                                  Register Now
                                </Button>
                              </a>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>

                      {props?.data?.Company?.cp_one_name || props?.data?.Company?.cp_two_name ? (
                        <div
                          className={!loggedIn ? 'd-none' : null + 'position-absolute ' + ' rbt-single-widget widget_recent_entries'}>
                          <div className="inner">
                            <div className="mt-3">
                              <span className="supplier-name ">
                                <GiHamburgerMenu color="#666666" className="mb-1 mr-2" />
                                <Link to={'/company-details/' + companyId} target="_blank">
                                  <b className="">
                                    {props?.data?.Company?.name}
                                  </b>
                                </Link>
                              </span>
                            </div>

                            <CompanyInformation info={props?.data?.Company} />
                            <div className="d-flex justify-content-between ">
                              {' '}
                              {loggedIn ? (
                                <a
                                  onClick={() => setNewMessage(true)}
                                  className="btn btn-chat btn-icon text-white mt-3"
                                >
                                  Chat Now
                                </a>
                              ) : (
                                <a
                                  href={process.env.REACT_APP_SAARC_DEFAULT_URL + '/users/login'}
                                  className="btn btn-chat btn-icon text-white mt-3"
                                >
                                  Chat Now
                                </a>
                              )}

                              <a className="btn btn-chat btn-icon ml-1 text-white mt-3  " onClick={() => setShowQueryModal(true)}>
                                Send Query
                              </a>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <div className=" data-element-sidebar-product rbt-single-widget widget_recent_entries mt-4">
                        <h5 className="inner mb-3">
                          <GiHamburgerMenu color="#666666" /> {'   '}Quick Links for
                        </h5>
                        <div className="inner">
                          <ProductQuickLinks country={props?.data?.Company?.company_country} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* TABS AREA */}
        <div className="rn-blog-area mt--30 mb-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Tabs defaultIndex={URLparameters.get('t') ? URLparameters.get('t') : 0}>
                  <div className="row row--30 align-items-center">
                    <div className="col-lg-12 mt_md--40 mt_sm--40">
                      <div className="rn-default-tab">
                        <div className="tab-container">
                          <TabList className="tab-button spaced-tabs">
                            {/* You may also like */}
                            {similarProducts && similarProducts.length > 0 && (
                              <Tab>
                                <div className="rn-tab-button custom-tab-button">
                                  <button>You May Also Like</button>
                                </div>
                              </Tab>
                            )}

                            {/* Company other products */}
                            {companyOtherProduct && companyOtherProduct.length > 0 && (
                              <Tab>
                                <div className="rn-tab-button custom-tab-button">
                                  <button>Company Other Products</button>
                                </div>
                              </Tab>
                            )}

                            {/* FAQS */}
                            {props?.data?.faqs && typeof props.data.faqs === 'object' &&
                              Object.keys(props.data.faqs).length !== 0 &&
                              Object.values(props.data.faqs)[0] !== '' &&
                              Object.values(props.data.faqs) !== '' && (
                                <Tab>
                                  <div className="rn-tab-button custom-tab-button">
                                    <button>FAQs</button>
                                  </div>
                                </Tab>
                              )
                            }

                            <Tab>
                              <div className="rn-tab-button custom-tab-button">
                                <button>Query Form</button>
                              </div>
                            </Tab>
                            <Tab>
                              <div className="rn-tab-button custom-tab-button">
                                <button>Ratings & Reviews</button>
                              </div>
                            </Tab>

                            {/* Brochure */}
                            {props?.data?.brochure && (
                              <Tab>
                                <div className="rn-tab-button custom-tab-button">
                                  <button>Product Literature</button>
                                </div>
                              </Tab>
                            )}
                          </TabList>
                        </div>

                        <div className="tab-content-panel">
                          {/* You may also like */}
                          {similarProducts && similarProducts.length > 0 ? (
                            <TabPanel>
                              <div className="rn-tab-content">
                                <div className="inner">
                                  <div className="data-element-sidebar-product">
                                    <SectionTitle id="similar-products" textAlign="text-center" title="You May Also Like" titleLine="title-line" />
                                    <Slider {...similar_settings} className="mb-5 ml_dev-15">
                                      {similarProducts.map((item) => (
                                        <div key={item.id} className="col-12">
                                          <RecommendedProducts
                                            StyleVar="box-card-style-default"
                                            data={item}
                                          />
                                        </div>
                                      ))}
                                    </Slider>
                                  </div>
                                </div>
                              </div>
                            </TabPanel>
                          ) : null}


                          {/* Company Other Products */}
                          {companyOtherProduct && companyOtherProduct.length > 0 && (
                            <TabPanel>
                              <div className="rn-tab-content">
                                <div className="inner">
                                  <div className="data-element-sidebar-product">
                                    <div id="youmayalsolike">
                                      <SectionTitle id="similar-products" textAlign="text-center" title={`Other Products By ${props?.data?.Company?.name}`} titleLine="title-line" />
                                    </div>
                                    <Slider {...similar_settings} className="mb-5 ml_dev-15">
                                      {companyOtherProduct.map((item) => (
                                        <div key={item.id} className="col-12">
                                          <RecommendedProducts
                                            StyleVar="box-card-style-default"
                                            data={item}
                                          />
                                        </div>
                                      ))}
                                    </Slider>
                                  </div>
                                </div>
                              </div>
                            </TabPanel>
                          )}


                          {/* FAQs */}
                          {props?.data?.faqs && typeof props.data.faqs === 'object' &&
                            Object.keys(props.data.faqs).length !== 0 &&
                            Object.values(props.data.faqs)[0] !== '' &&
                            Object.values(props.data.faqs) !== '' && (
                              <TabPanel>
                                <div className="rn-tab-content">
                                  <div className="inner">
                                    <div className="data-element-sidebar-product">
                                      <SectionTitle textAlign="text-center" radiusRounded="" subtitle="Accordion" title="Frequently Asked Questions" description="" />
                                      <span id="faqs">
                                        <FAQs faqs={props.data.faqs} />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </TabPanel>
                            )
                          }


                          { }
                          <TabPanel>
                            <div className="rn-tab-content">
                              <div className="inner">
                                <div className="data-element-sidebar-product">
                                  <div className="mt-5">
                                    <span>
                                      <SectionTitle textAlign="text-center" title="Query Form" />
                                    </span>
                                  </div>
                                  <QueryForm company={props?.data?.Company} productData={props.data} preview={props.preview} />
                                </div>
                              </div>
                            </div>
                          </TabPanel>

                          {/* Ratings and  Reviews */}
                          <TabPanel>
                            <div className="rn-tab-content">
                              <div className="inner">
                                <div className="data-element-sidebar-product">
                                  <div className="row">
                                    <div className="col-6">
                                      <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '0px' }}>{isNaN(sum / productReview.length) ? 'No Rating' : sum / productReview.length} <span style={{ fontSize: '14px', fontWeight: 'normal', display: 'inline' }}>/5</span><Star selected={5} /><Star selected={5} /><Star selected={5} /><Star selected={5} /></p>
                                      <p>Reviewed by {productReview.length} users</p>
                                    </div>
                                    <div className="col-4">
                                      <div className='d-flex' style={{ height: '20px' }}>
                                        <p style={{ marginRight: '10px', fontSize: '12px' }}>5 stars</p>
                                        <ProgressBar
                                          now={fiveStarCount / productReview.length * 100}
                                          variant="success"
                                          style={{ backgroundColor: 'lightgray', flex: 1, marginBottom: '30px' }}
                                          className='mt-3'
                                        />
                                        <p style={{ marginLeft: '10px', fontSize: '12px' }}>
                                          {isNaN(fiveStarCount / productReview.length * 100) ? '0' : `${(fiveStarCount / productReview.length * 100)}%`}
                                        </p>
                                      </div>
                                      <div className='d-flex' style={{ height: '20px' }}>
                                        <p style={{ marginRight: '10px', fontSize: '12px' }}>4 stars</p>
                                        <ProgressBar
                                          now={fourStarCount / productReview.length * 100}
                                          variant="success"
                                          style={{ backgroundColor: 'lightgray', flex: 1, marginBottom: '30px' }}
                                          className='mt-3'
                                        />
                                        <p style={{ marginLeft: '10px', fontSize: '12px' }}>
                                          {isNaN(fourStarCount / productReview.length * 100) ? '0' : `${fourStarCount / productReview.length * 100}`}%
                                        </p>
                                      </div>
                                      <div className='d-flex' style={{ height: '20px' }}>
                                        <p style={{ marginRight: '10px', fontSize: '12px' }}>3 stars</p>
                                        <ProgressBar
                                          now={threeStarCount / productReview.length * 100}
                                          variant="success"
                                          style={{ backgroundColor: 'lightgray', flex: 1, marginBottom: '30px' }}
                                          className='mt-3'
                                        />
                                        <p style={{ marginLeft: '10px', fontSize: '12px' }}>
                                          {isNaN(threeStarCount / productReview.length * 100) ? '0' : `${threeStarCount / productReview.length * 100}`}%
                                        </p>
                                      </div>
                                      <div className='d-flex' style={{ height: '20px' }}>
                                        <p style={{ marginRight: '10px', fontSize: '12px' }}>2 stars</p>
                                        <ProgressBar
                                          now={twoStarCount / productReview.length * 100}
                                          variant="success"
                                          style={{ backgroundColor: 'lightgray', flex: 1, marginBottom: '30px' }}
                                          className='mt-3'
                                        />
                                        <p style={{ marginLeft: '10px', fontSize: '12px' }}>
                                          {isNaN(twoStarCount / productReview.length * 100) ? '0' : `${twoStarCount / productReview.length * 100}`}%
                                        </p>
                                      </div>
                                      <div className='d-flex' style={{ height: '20px' }}>
                                        <p style={{ marginRight: '10px', fontSize: '12px' }}>1 star{` `}</p>
                                        <ProgressBar
                                          now={oneStarCount / productReview.length * 100}
                                          variant="success"
                                          style={{ backgroundColor: 'lightgray', flex: 1, marginBottom: '30px', width: '200px' }}
                                          className='mt-3'
                                        />
                                        <p style={{ marginLeft: '10px', fontSize: '12px' }}>
                                          {isNaN(oneStarCount / productReview.length * 100) ? '0' : `${oneStarCount / productReview.length * 100}`}%
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <h6 className="mt-2">Comments</h6>
                                    {reviewsState && reviewsState[0]?.is_fulfilled === true && (
                                      <>
                                        <div className="d-flex">
                                          <div className="dropdown">
                                            {createArrays(5).map((n, i) => (
                                              <Star key={i} selected={selectedStar > i} onSelect={() => setSelectedStar(i + 1)} />
                                            ))}
                                            <div className="dropdown-content">
                                              <Popover id="popover-basic"></Popover>
                                            </div>
                                          </div>

                                          <p className="mt-1 cursor-pointer" style={{ fontSize: '14px', marginBottom: '10px' }} >
                                            ({selectedStar} Rating)
                                          </p>
                                        </div>

                                        <input
                                          placeholder='Write your review here'
                                          className="mb-2"
                                          name='comment'
                                          value={reviewsState.comment}
                                          onChange={handleChange}
                                        />
                                        {/* <button className="btn-secondary p-2 mb-5" type="button" onClick={handleCancel}>
                                          Cancel
                                        </button> */}
                                        <button className='btn-primary p-2 mb-5' onClick={handleSubmit}>
                                          Add Review
                                        </button>
                                      </>
                                    )}
                                  </div>
                                  <div>
                                    {productReview.map((item) => (
                                      <Card key={item.id}>
                                        <CardBody>
                                          <div className="reviews-top">
                                            <div className="user-details d-flex align-items-center">
                                              <CardImg
                                                style={{ borderRadius: '50%', width: '50px', height: '50px' }}
                                                src={`https://ui-avatars.com/api/?name=${item.user_name?.charAt(0)?.toUpperCase()}&size=50`}
                                                alt=""
                                              />
                                              <h6 className="mb-2 text-muted ml-2 mr-3">{item.user_name}</h6>
                                              {createArray(5, item.rating)}
                                            </div>
                                            <div className="reviews-body">
                                              <CardText>
                                                {item.comment || ''}
                                              </CardText>
                                            </div>
                                            <CardText>
                                              <small className="text-muted text-bold">
                                                {item.createdAt?.slice(0, 10) || ''}
                                              </small>
                                            </CardText>
                                          </div>
                                        </CardBody>
                                      </Card>
                                    ))
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPanel>

                          {props?.data?.brochure && (
                            <TabPanel>
                              <PdfReader
                                className="pdfReader"
                                url={brochurePath}
                              />
                              <a
                                className="mt-5 color-black"
                                href={brochurePath}
                                target="_blank"
                              >
                                Click to view in browser
                              </a>
                            </TabPanel>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetailsContent;
