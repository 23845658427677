import React, { useState, useRef, useEffect } from 'react';
import Logo from '../../elements/logo/Logo';
import MobileMenu from './MobileMenu';
import Darkmode from './Darkmode';
import useStickyHeader from './useStickyHeader';
import { currency_list, getUnreadMessages, getUserId, isLoggedIn, USER_ROLE } from '../../utils';
import { get } from '../../utils/api_helper';
import { Button, DropdownButton } from 'react-bootstrap';
import Dropdown from 'react-multilevel-dropdown';
import DropdownItem from 'react-bootstrap/DropdownItem';
import { FiLogOut, FiShoppingCart } from 'react-icons/fi';
import { MdOutlineSpaceDashboard } from 'react-icons/md';
import { CgProfile } from 'react-icons/cg';
import { FaUserCircle } from 'react-icons/fa';
import { AiFillEdit, AiFillShop, AiOutlineBars, AiOutlineCaretDown, AiOutlineShoppingCart } from 'react-icons/ai';
import { AiOutlineMessage, AiOutlineMail } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { BiCurrentLocation } from 'react-icons/bi';
import { menus } from '../../utils';
import axios from 'axios';
import { BsCart, RiProfileLine } from 'react-icons/all';
import * as api from '../../utils/api_helper';
import Select from 'react-select';
import { languages } from '../../utils/constants';
import { useCookies } from 'react-cookie';
import { lang } from 'moment';
import { BsHeartFill } from 'react-icons/bs';
import SearchBar from './SearchBar';

const HeaderOne = ({ btnStyle, HeaderSTyle, showSlider=true }) => {
  const [ofCanvasShow, setOffCanvasShow] = useState(false);
  const [userData, setUserData] = useState('');
  let [check, setCheck] = useState(true);
  const sticky = useStickyHeader(50);
  const headerClasses = `header-default ${sticky && check ? 'sticky' : ''}`;
  const [refetch, setRefetch] = useState(0);
  const [registered, setRegistered] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [currency, setCurrency] = useState('usd');
  const [companyID, setCompanyID] = useState('');
  const [messageNotification, setMessageNotification] = useState(null);
  const [cartCount, setCartCount] = useState(0);
  const [openDropdown, setOpenDropdown] = useState(false)
  const [cur, setCur] = useState(localStorage.getItem('cur') || 'USD');
  const [cookies, setCookie] = useCookies(['googtrans']);  // For language updating
  const [language, setLanguage] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const userId = getUserId();
  const onCanvasHandler = () => { setOffCanvasShow((prev) => !prev)};
  const ref = useRef();

  // Custom styles for language and currency react select
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
      height: '30px',
      padding: '5px',
      'fontSize': '12px',
    }),
    control: (provided, state) => ({
      ...provided,
      'background-color': 'white',
      'text-align': 'left',
      'vertical-align': 'left',
      'fontSize': '14px',
    }),
    singleValue: (provided, state) => {
      const transition = 'opacity 300ms';
      return { ...provided, transition };
    },
  };

  // Get IP Address for currency 
  const getIpAddress = async () => {
    await axios
      .get('https://geolocation-db.com/json/')
      .then((res) => {
        getCurrencyFromIP(res?.data?.IPv4);
      })
      .catch((err) => {
        console.error('Error fetching IP location', err);
        getCurrencyFromIP('');
      });
  };

  // Get currency from IP address
  const getCurrencyFromIP = async (ipAddress) => {
    if (ipAddress) {
      await axios
        .get('https://ipapi.co/' + ipAddress + '/currency/')
        .then((res) => {
          setCurrency(res.data);
          localStorage.setItem('cur', res.data);
          window.location.reload(true);
        })
        .catch((err) => {
          console.error('Error fetching currency from IP address', err);
        });
    } else {
      setCurrency('USD');
    }
  };

  // Handle setting currencies
  useEffect(() => {
    const googleTransCookie = cookies?.googtrans;
    setLanguage(googleTransCookie ? googleTransCookie.split('/')[2] : 'en');
    const currentCurrency = localStorage.getItem('cur');
    // If no currency is found in localstorage, then show IP based currency
    if (!currentCurrency) {
      getIpAddress();
    }
  }, []);

  // // Select currency
  // const selectCurrency = (currencyCode) => {
  //   window.location.reload(true);
  //   localStorage.setItem('cur', currencyCode);
  //   setCur(currencyCode);
  // };

  // Handle save for saving currency and language preferences
  const saveLanguageAndCurrency = async () => {
    // *** Update Language ***
    const googleTransCookie = cookies?.googtrans;

    // If there is no language in cookies, set english as current language
    const currentLanguage = googleTransCookie ? googleTransCookie.split('/')[0] : 'en';

    setCookie('googtrans', `/${currentLanguage}/${selectedLanguage}`);

    // *** Update currency ***
    localStorage.setItem('cur', selectedCurrency);
    setCur(selectedCurrency);
    window.location.reload(true);
  }

  useEffect(() => {
    // Get unread messages
    async function getCount() {
      const resp = await getUnreadMessages();
      setMessageNotification(resp);
    }

    // Get cart count
    async function getCartCount() {
      if (userId) {
        api
          .get('/cart/user/' + userId)
          .then((response) => {
            if (response[0]) {
              if (response[0].product_title) {
                setCartCount(response[0].product_title.split(';').length);
              } else {
                setCartCount(0);
              }
            }
          })
          .catch((error) => {
            console.error('Error fetching cart', error);
          });
      }
    }
    if (isLoggedIn()) {
      getCount();
      getCartCount();
      setTimeout(() => {setRefetch(refetch + 1);}, 5000);
    }
  }, [refetch]);

  useEffect(() => {
    if (userId) {
      async function getUserName() {
        await get('/users/' + userId)
          .then((resp) => {
            setUserData(resp);
            if (resp?.role == USER_ROLE.ADMIN) {
              setIsAdmin(true);
            }
          })
          .catch((err) => {
            console.error('error', err);
          });
      }
      getUserName();
    }
  }, []);

  useEffect(() => {
    if (userId) {
      async function getUserName() {
        await get('/companies/user/' + userId)
          .then((resp) => {
            if (resp && Array.isArray(resp) && resp.length > 0 && resp[0].id) {
              setRegistered(true);
              setCompanyID(resp[0].id);
            }
          })
          .catch((err) => {
            console.error('error', err);
          });
      }
      getUserName();
    }
  }, []);

  // Currency constant for react select
  const currencyOptions = currency_list.map((item) => ({
    value: item.code,
    label: `${item.name} ${item.code}`,
    image: `/images/country-flags/${item.img}.png`
  }));

  // Handle select change
  const handleSelectChange = async (value, action) => {
    if (action.name == 'currency') {
      setSelectedCurrency(value.value);
    } else if (action.name == 'language') {
      setSelectedLanguage(value.value);
    }
  }


  return (
    <>
      <header ref={ref} className={`rn-header header-default ${HeaderSTyle} ${headerClasses}`}>
        <div className="position-relative container">
          <div className="row align-items-center row--0">
            <div className="col-2">
              <div className="row">
                <div className="mr-3 mt-4 categories d-sm-none d-xs-none">
                  {menus.map((menu) => (
                    <Dropdown
                      title={<AiOutlineBars size={22} />}
                      position="right"
                      menuClassName="text-14 py-8 px-5 my-0 mx-16 border-b-1 border-solid border-blue hover:border-black"
                    >
                      {menu.children &&
                        menu.children.map((item) => (
                          <Dropdown.Item>
                            <Link
                              to={
                                'search?s=&pcat=' +
                                item.name.replaceAll(/\s/g, '-').replaceAll('&', 'and')
                              }
                            >
                              {item.name}
                            </Link>
                            <Dropdown.Submenu position="right" className="categories-submenu">
                              {item.submenu &&
                                item.submenu.map((submenu) => (
                                  <Dropdown.Item>
                                    <Link
                                      to={
                                        '/search?s=&cat=' +
                                        submenu.name.replaceAll(/\s/g, '-').replaceAll('&', 'and')
                                      }
                                    >
                                      {submenu.name}
                                    </Link>
                                  </Dropdown.Item>
                                ))}
                              <DropdownItem>
                                <Link to="/categories">See All Categories</Link>
                              </DropdownItem>
                            </Dropdown.Submenu>
                          </Dropdown.Item>
                        ))}
                      <DropdownItem>
                        <Link to="/categories">See All Categories</Link>
                      </DropdownItem>
                    </Dropdown>
                  ))}
                </div>
                <Logo
                  image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                  image2={`${process.env.PUBLIC_URL}/images/logo/logo-dark.png`}
                />
              </div>
            </div>
            {!showSlider && (
              <div className='col-8'>
                <SearchBar />
              </div>
            )}
            <div className={`position-static ${!showSlider ? ' col-2' : ' col-10'}`}>
              <div className="header-right">
                <div className="dropdown-homepage row">
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {isLoggedIn() ? (
                      <>
                        <DropdownButton
                          className="float-left dropdown-button"
                          drop="down"
                          title={
                            <a>
                              <span className="avatar-small">
                                {userData && userData.username.charAt(0)}
                              </span>

                              <span
                                style={{ fontSize: '14px' }}
                                className="color-black ml-2 text-lowercase username"
                              >
                                {userData.username}
                              </span>
                            </a>
                          }
                          size="lg"
                          id="nav-dropdown "
                        >
                          <div className="user-corner" />
                          <div className="avatar text-center">
                            <span className="avatar-big">
                              {userData && userData.username.charAt(0)}
                            </span>
                            <h5 className="mb-0">{userData.username}</h5>
                            <label>{userData.email}</label>
                          </div>
                          <DropdownItem className="dropdown-link" href="/users/dashboard">
                            <MdOutlineSpaceDashboard />
                            &nbsp;Dashboard
                          </DropdownItem>
                          <DropdownItem className="dropdown-link" href="/users/product-wishlist">
                            <BsHeartFill />
                            &nbsp;Wishlist
                          </DropdownItem>
                          <DropdownItem className="dropdown-link" href="/users/messages">
                            <AiOutlineMessage />
                            &nbsp;Messages
                          </DropdownItem>
                          <DropdownItem
                            className="dropdown-link company-profile-menu"
                            href={'/company-details/' + companyID}
                          >
                            <RiProfileLine />
                            &nbsp;Company Profile
                          </DropdownItem>
                          <DropdownItem
                            className="dropdown-link edit-company "
                            href={'/users/company-registration'}
                          >
                            <span className="ml-5">
                              <AiFillEdit />
                              &nbsp;Edit Company
                            </span>
                          </DropdownItem>
                          <DropdownItem className="dropdown-link my-shop" href={'users/listings'}>
                            <AiFillShop />
                            &nbsp;My Shop
                          </DropdownItem>
                          <DropdownItem
                            className="dropdown-link edit-products"
                            href={'/users/add-product'}
                          >
                            <span className="ml-5">
                              <AiFillEdit />
                              &nbsp;Add Products
                            </span>
                          </DropdownItem>
                          <DropdownItem className="dropdown-link" href="/users/profile">
                            <CgProfile />
                            &nbsp;Account Details
                          </DropdownItem>
                          <DropdownItem className="dropdown-link" href="/users/logout">
                            <FiLogOut />
                            &nbsp;Logout
                          </DropdownItem>
                        </DropdownButton>
                        <div className=" mt-1 ml-2 mr-4">
                          <a href="/cart">
                            <FiShoppingCart color="black" size={20} />
                            <span className="badge cart-badge bg-danger rounded-pill color-white">
                              {cartCount}
                            </span>
                          </a>
                        </div>
                        <div className=" mt-1 ml-2 mr-4">
                          <a href="/users/messages">
                            <AiOutlineMail color="black" size={20} />
                            <span className="badge bg-danger rounded-pill color-white">
                              {messageNotification}
                            </span>
                          </a>
                        </div>
                      </>
                    ) : (
                      <>
                        <span className="signin d-sm-block d-lg-block d-md-block d-xs-none">
                          <FaUserCircle size={20} /> {'  '}
                          <a href="/users/login">Sign in</a> or <a href="/users/register">Register</a>
                        </span>
                        <span className="signin d-md-none d-lg-none d-sm-none">
                          <FaUserCircle size={20} /> {'  '}
                          <a href="/users/login">Login</a>
                        </span>
                      </>
                    )}
                    <div className='ml-2'>
                      <button className='currency-language-button' onClick={() => setOpenDropdown(!openDropdown)} style={{ position: "relative", color: "black", fontSize: 12, fontWeight: 500, cursor: "pointer" }}>{`${cur.toUpperCase()}/${language.toUpperCase()}`}</button>
                      <div className={openDropdown ? '' : 'd-none '} style={{ position: "absolute", top: 60, left: 890, backgroundColor: "#fff", width: "250px", borderRadius: '10px' }}>
                        <div className="mt-1 px-3 pt-4 ">
                          <div className='custom-switcher'>
                            <div className='switch-item'>
                              <p className='label no-margin'>Language</p>
                              <div className='skipMyTranslate'>
                                <div className='skiptranslate'>
                                  <Select
                                    name='language'
                                    options={languages}
                                    styles={customStyles}
                                    onChange={handleSelectChange}
                                    value={!selectedLanguage ? { label: languages.find(option => option.value == language)?.label } : { label: languages.find(option => option.value == selectedLanguage)?.label }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='switch-item mt-3'>
                              <p className='label no-margin'>Currency</p>
                              <div className='skipMyTranslate'>
                                <div className='skiptranslate'>
                                  <Select
                                    name='currency'
                                    options={currencyOptions}
                                    styles={customStyles}
                                    onChange={handleSelectChange}
                                    value={!selectedCurrency
                                      ? { label: currencyOptions.find(option => option.value === cur)?.label, image: currencyOptions.find(option => option.value === cur)?.image }
                                      : { label: currencyOptions.find(option => option.value === selectedCurrency)?.label, image: currencyOptions.find(option => option.value === selectedCurrency)?.image }
                                    }                                    
                                    formatOptionLabel={country => (
                                      <div className="country-option">
                                        <img width="15px" height="15px" src={country.image}/> 
                                        &nbsp;<span>{country.label}</span>
                                      </div>
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='text-center'>
                              <button onClick={saveLanguageAndCurrency} className="mt-3 btn-default btn-small mb-3" style={{ width: '100%' }}>Save</button>
                            </div>
                          </div>
                        </div>
                        {/* <div id="google_translate_element"></div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <Darkmode />
              </div>
            </div>
          </div>
        </div>
      </header>
      <MobileMenu show={ofCanvasShow} onClose={onCanvasHandler} />
    </>
  );
};
export default HeaderOne;
