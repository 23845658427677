import React from 'react';

const CopyrightTwo = () => {
  return (
    <div className="copyright-area copyright-style-one variation-two bg-color-footer">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt_md--20 mt_sm--20">
            <div className="copyright-right text-center text-lg-right">
              <p className="copyright-text">
                Copyright © {new Date().getFullYear()} SAARC BAZAAR.
              </p>
              <p className="copyright-text">
                In collaboration with{' '}
                <a href="https://www.freiheit.org/">
                  Friedrich Naumann Foundation for Freedom (FNF)
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CopyrightTwo;
