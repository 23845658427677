import React, { useEffect, useState } from 'react';
import ProductDetailsContent from '../elements/portfolio/ProductDetailsContent';
import SEO from '../common/SEO';
import Layout from '../common/Layout';
import { get } from '../utils/api_helper';
import { COMPANY_STATUS, getUserId, getUserRole, PRODUCT_STATUS, USER_ROLE } from '../utils';
import Error from './Error';
import axios from 'axios';

const ProductDetails = (props) => {
  const productId = props.match.params.id;
  const preview = props.match.params.preview;
  const [listingData, setListingData] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [variation1, setVariation1] = useState([]);
  const [variation2, setVariation2] = useState([]);
  const [variationData,setVariationData] = useState([]);
  const [currencyPerUnit, setCurrencyPerUnit] = useState(0);
  const [userSelectedCurrency, setUserSelectedCurrency] = useState(localStorage.getItem('cur'));
  const loop = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const userRole = getUserRole();
  const userId = getUserId();
  let url = '';

  // Fetch currency conversion list
  async function convertCurrency(productCurrency) {
    await axios
      .get('https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/' + productCurrency + '.json')
      .then((res) => {
        const currencyList = res.data[productCurrency][userSelectedCurrency.toLowerCase()]
        console.log(currencyList)
        setCurrencyPerUnit(currencyList);
      })
      .catch((err) => {
        console.error('Error fetching currency conversion', err);
      });
  }

  useEffect(() => {
    setLoading(true);
    async function getListing() {
      if (!productId) return;
      if (preview === 'preview') {
          url = '/previewlistings/';
      }
      else if (preview === 'update') {
          url = '/templisting/'
      }
      else {
          url = /listings/;
      }

      // Get listing
      await get(url + productId)
        .then(async (response) => {
          if (response.success == true) {
            // If product has currency specified and price specified, then fetch currency conversion list
            if (response?.product?.currency && response?.product?.price) {
              await convertCurrency(response.product.currency);
            }
            setListingData(response.product);
            setAttachments(response.attachments);
            if (response?.product?.Variations?.length > 0) {
              setVariationData(response.product.Variations);
              processVariations(response?.product?.Variations)
            } else {
              setLoading(false);
            }
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          console.error('Error product not found', err);
        });
    }
    getListing();
  }, [productId]);

  // Process variaions to save them as we want
  const processVariations = (variations) => {
    let tempVariation1 = [];
    let tempVariation2 = [];

    for (let variation of variations) {
      tempVariation1.push(variation?.variation1?.trim());
      tempVariation2.push(variation?.variation2?.trim());
    }

    // Create unique sets
    tempVariation1 = [...new Set(tempVariation1)];
    tempVariation2 = [...new Set(tempVariation2)];

    // Set states
    setVariation1(tempVariation1);
    setVariation2(tempVariation2);
    setLoading(false);
  }

  return loading ? (
    <div className="spinner-custom centered custom-size-spinner" role="status">
      {loop.map((x) => (<span id={x} className="sr-only-custom" />))}
    </div>
  ) : (
    <>
      {/*{url === 'templisting' ? <></> : null}*/}
      {((listingData && listingData.status === PRODUCT_STATUS.APPROVED ||
        listingData.status === PRODUCT_STATUS.PUPDATE) &&
        listingData.state === true &&
        listingData.Company.state === true &&
        listingData.Company.status === COMPANY_STATUS.APPROVED) ||
      (listingData &&
        listingData.User &&
        (userRole === USER_ROLE.ADMIN || userId === listingData.UserId)) ? (
        <>
            <Layout>
            <ProductDetailsContent
              variationData ={variationData}
              data={listingData}
              productId={productId}
              companyId={listingData.CompanyId}
              username={listingData.User.username}
              preview={preview}
              variation1={variation1}
              variation2={variation2}
              attachments={attachments}
              currencyPerUnit={currencyPerUnit}
            />
          </Layout>
        </>
      ) : (
        <>
          <SEO title={'404 Not Found'} />

            <Error />
        </>
      )}


    </>
  );
};

export default ProductDetails;
