import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { BannerActivation } from '../../utils/script';
import { BsSearch, FaMapMarkerAlt } from 'react-icons/all';
import { get } from '../../utils/api_helper';
import { InputGroup, Form } from 'react-bootstrap';
import Select from 'react-select';
import { Button } from 'react-bootstrap';

const SearchBar = () => {
  const [listings, setListings] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [searchtext, setSearchText] = useState('');
  const [searchOption, setSearchOption] = useState({ option: 'products' });
  const onSelectionChange = (value, action) => {
    setSearchOption({ [action.name]: value.value });
  };
  const options = [
    { value: 'suppliers', label: 'Suppliers' },
    { value: 'products', label: 'Products' },
  ];
  useEffect(() => {
    async function getProducts() {
      await get('/listings')
        .then((resp) => {
          setListings(resp);
        })
        .catch((err) => {
          console.error('error', err);
        });
    }
    async function getSuppliers() {
      await get('/companies')
        .then((resp) => {
          setSuppliers(resp);
        })
        .catch((err) => {
          console.error('error', err);
        });
    }
    getSuppliers();
    getProducts();
  }, []);

  const onChangeHandler = (text) => {
    let matches = [];
    if (text.length > 0) {
      if (searchOption.option === 'products') {
        matches = listings.filter((listings) => {
          const regex = new RegExp(`${text}`, 'gi');
          return listings.title.match(regex);
        });
      } else if (searchOption.option === 'suppliers') {
        matches = suppliers.filter((supplier) => {
          const regex = new RegExp(`${text}`, 'gi');
          return supplier.name.match(regex);
        });
      }
    } else {
      setSuggestions([]);
    }
    setSuggestions(matches);
    setSearchText(text);
  };
  const onSuggestHandler = (text) => {
    setSearchText(text);
    setSuggestions([]);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
      height: '30px',
      padding: '5px',
      'font-size': '12px',
    }),
    indicatorsContainer: (prevStyle, state) => ({
      ...prevStyle,
      display: 'inline',
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '-3px',
    }),
    control: (provided, state) => ({
      'background-color': 'white',
      'border-right': '0.5px solid #F2F2F2',
      'border-radius': '20px 0px 0px 20px',
      'text-align': 'center',
      'vertical-align': 'center',
      height: '60px',
      width: '100%',
      'font-size': '14px',
    }),
    singleValue: (provided, state) => {
      const transition = 'opacity 300ms';
      return { ...provided, transition };
    },
  };

  return (
    <>
      <div className='pr-3'>
        <form action="/search" onSubmit="/search">
          <InputGroup>
            {/* <Select
              name="option"
              options={options}
              styles={customStyles}
              onChange={onSelectionChange}
              defaultValue={{ label: 'Products', value: 'products' }}
            /> */}
            <Form.Control
              style={{ 'font-size': '14px', 'box-shadow': 'none !important' }}
              autoComplete="off"
              className="shadow-none"
              type="text"
              placeholder="Search Here..."
              name="s"
              onChange={(e) => onChangeHandler(e.target.value)}
              value={searchtext}
            />
            <Button type="submit" className="btn-info" style={{ padding: '15px' }}>
              <BsSearch size="18" />
            </Button>
          </InputGroup>
        </form>
        {/* {suggestions &&
          suggestions.slice(0, 6).map((suggestion, i) => (
            <div
              className="suggestions ml--80 pl-5"
              key={i}
              onClick={() => onSuggestHandler(suggestion.title || suggestion.name)}
            >
              <span style={{ 'font-size': '12px' }}>{suggestion.title || suggestion.name}</span>
            </div>
          ))} */}
      </div>
    </>
  );
};
export default SearchBar;
