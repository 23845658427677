import React from 'react';
import { BsTwitter, AiFillEye, AiFillYoutube, AiOutlineInstagram, BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill, FaFacebookF, FaLinkedinIn, AiFillEyeInvisible } from 'react-icons/all';

const FacebookPopup = ({showSlider, setShowSlider, popup}) => {
  const [facebookPopup, setFacebookPopup] = React.useState(popup);
  const handleCloseReport = () => {
    setFacebookPopup(!facebookPopup);
  };

  return (
    <div className=' d-xs-none d-sm-none d-md-block d-lg-block d-xl-block'> {/* Hide on small screens */}
      <div className={!facebookPopup ? 'show-follow-this' : 'd-none '}>
        <ul>
          <li>
            <a onClick={handleCloseReport} className="cursor-pointer">
              <BsFillArrowLeftCircleFill size={20} color="white" className='zoom-animation'/>
            </a>
          </li>
        </ul>
      </div>
      <div className={facebookPopup ? ' follow-this ' : 'd-none'}>
        <ul>
          <li>
            <a onClick={handleCloseReport} className="cursor-pointer">
              <BsFillArrowRightCircleFill size={20} color="white" className='zoom-animation' />
            </a>
          </li>
          <li className="facebook-follow">
            <a
              className="cursor-pointer"
              href="https://www.facebook.com/profile.php?id=100083029855856"
              target="_blank"
            >
              <FaFacebookF size={25} color="white" />
            </a>
          </li>
          <li className="twitter-follow">
            <a className="cursor-pointer" href="https://twitter.com/SAARCBazaar" target="_blank">
              <BsTwitter size={25} color="white" />
            </a>
          </li>
          <li className="youtube-follow">
            <a
              className="cursor-pointer"
              href="https://www.youtube.com/channel/UCdnTy56mdogZofDnNhvaxOQ"
              target="_blank"
            >
              <AiFillYoutube size={25} color="white" />
            </a>
          </li>
          <li className="insta-follow">
            <a
              href="https://www.instagram.com/saarc.bazaar"
              target="_blank"
              className="cursor-pointer"
            >
              <AiOutlineInstagram size={25} color="white" />
            </a>
          </li>
          <li className="linkedin-follow">
            <a
              href="https://www.linkedin.com/company/saarc-bazaar/"
              target="_blank"
              className="cursor-pointer"
            >
              <FaLinkedinIn size={25} color="white" />
            </a>
          </li>
          {showSlider ?
            <li className="hide-slider-button">
              <a
                target="_blank"
                className="cursor-pointer"
                title='Hide Slider'
                onClick={() => {console.log('setting false'); setShowSlider(false)}}
              >
                <AiFillEyeInvisible size={25} color="white" />
              </a>
            </li>
            :
            <li className="hide-slider-button">
              <a
                target="_blank"
                className="cursor-pointer"
                title='Show Slider'
                onClick={() => setShowSlider(true)}
              >
                <AiFillEye size={25} color="white" />
              </a>
            </li>
          }
        </ul>
      </div>
    </div>
  );
};
export default FacebookPopup;
